import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Theme from '../../../../Themes/ThemeArtLexie';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Settings from '../../Settings/Settings'
import ImageContainer from '../ImageComponent/ImageContainer'
import Grid from '@material-ui/core/Grid';
import Pagination from '../Pagination/PaginationComponent'
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import SearchAllImages from './MaterialComponents/SearchAllImages'
import ImageList from '@material-ui/core/ImageList';
import ImageComponentListItem from '../ImageComponent/ImageComponentListItem'
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VideoContainer from '../ImageComponent/VideContainer';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import SearchAllVideos from './MaterialComponents/SearchAllVideos';

export default class HomeComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.refDiv = React.createRef()
    }

    componentDidMount() {
        super.componentDidMount()
        let endpoint = Settings.API_ENDPOINT_UPLOAD_IMAGES
        let endpointVideos = Settings.API_ENDPOINT_SEARCH_VIDEOS
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_SEARCH_IMAGES + '?q=' + this.props.urlParams.query
            endpointVideos = Settings.API_ENDPOINT_SEARCH_VIDEOS  + '?q=' + this.props.urlParams.query
            if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
                endpoint += '&page=' + this.props.urlParams.page
                endpointVideos += '&page=' + this.props.urlParams.page
            }

        } else {
            if(this.props.urlParams && this.props.urlParams.page)
            {
                endpoint += '?page=' + this.props.urlParams.page
                endpointVideos += '&page=' + this.props.urlParams.page
            }
        }

        if (this.getQueryParams('videos') === '1')
            this.getSearchVideos(endpointVideos)
        else
            this.getSearchImages(endpoint)
    }

    changePage(page) {
        let endpoint = Settings.API_ENDPOINT_UPLOAD_IMAGES + '?page=' + page
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_SEARCH_IMAGES + '?q=' + this.props.urlParams.query + '&page=' + page
        }
        this.getSearchImages(endpoint)
    }

    getLastImagesID()
    {
        let imagesID = this.props.getManagerState().imagesResultset[0].imagesID;
        for (let i in this.props.getManagerState().imagesResultset)
        {
            if (parseInt(this.props.getManagerState().imagesResultset[i].imagesID) < parseInt(imagesID))
            imagesID = parseInt(this.props.getManagerState().imagesResultset[i].imagesID)
        }

        return imagesID;
    }

    getLastVideosID()
    {
        let videosID = this.props.getManagerState().videosResultset[0].videosID;
        for (let i in this.props.getManagerState().videosResultset)
        {
            if (parseInt(this.props.getManagerState().videosResultset[i].videosID) < parseInt(videosID))
                videosID = parseInt(this.props.getManagerState().videosResultset[i].videosID)
        }

        return videosID;
    }

    getOlderImages() {
        this.setState({ifeelLucky: false})
        this.props.setManagerState({loadingImages: true}, () => {
            let endpoint = Settings.API_ENDPOINT_SEARCH_IMAGES_OLDER + this.getLastImagesID()
            if(this.props.search === true) {
                endpoint = Settings.API_ENDPOINT_SEARCH_IMAGES_OLDER  + this.getLastImagesID() + '?q=' + this.props.urlParams.query
            }
            let loadedPage = this.props.getManagerState().loadedPage + 1
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                let olderImagesResultset = this.props.getManagerState().imagesResultset
                olderImagesResultset = olderImagesResultset.concat(data.items)
                this.props.setManagerState({imagesResultset: olderImagesResultset, loadingImages: false, loadedPage: loadedPage}, () => {})
            })
        })
    }

    getOlderVideos() {
        this.setState({ifeelLucky: false})
        this.props.setManagerState({loadingVideos: true}, () => {
            let endpoint = Settings.API_ENDPOINT_SERCH_VIDEOS_OLDER + this.getLastVideosID()
            if(this.props.search === true) {
                endpoint = Settings.API_ENDPOINT_SERCH_VIDEOS_OLDER  + this.getLastVideosID() + '?q=' + this.props.urlParams.query
            }
            let loadedPage = this.props.getManagerState().loadedPage + 1
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                let olderImagesResultset = this.props.getManagerState().videosResultset
                olderImagesResultset = olderImagesResultset.concat(data.items)
                this.props.setManagerState({videosResultset: olderImagesResultset, loadingVideos: false, loadedPage: loadedPage}, () => {})
            })
        })
    }


    getSearchImages(endpoint) {
        const that = this
        that.props.setManagerState({imagesResultset: [], currentPagination: null, loadingImages: true}, () => {
            that.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                that.props.setManagerState({imagesResultset: data.items, currentPagination: data.pagination, loadingImages: false}, () => {
                    that.mergeMedia()
                })
            })
        })
    }

    getSearchVideos(endpoint) {
        const that = this
        that.props.setManagerState({videosRestulset: [], currentPagination: null, loadingVideos: true}, () => {
            that.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                that.props.setManagerState({videosRestulset: data.items, currentPagination: data.pagination, loadingVideos: false}, () => {
                    that.mergeMedia()
                })
            })
        })
    }

    getOlderMedia() {
        this.setState({ifeelLucky: false})
        this.props.setManagerState({loadingImages: true, loadingVideos: true}, () => {
            let endpoint = Settings.API_ENDPOINT_SEARCH_IMAGES_OLDER + this.getLastImagesID()
            if(this.props.search === true) {
                endpoint = Settings.API_ENDPOINT_SEARCH_IMAGES_OLDER  + this.getLastImagesID() + '?q=' + this.props.urlParams.query
            }
            let loadedPage = this.props.getManagerState().loadedPage + 1
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                let olderImagesResultset = this.props.getManagerState().imagesResultset
                olderImagesResultset = olderImagesResultset.concat(data.items)
                this.mergeMedia()
                this.props.setManagerState({imagesResultset: olderImagesResultset, loadingImages: false, loadedPage: loadedPage}, () => {
                    this.mergeMedia()
                })
            })

            let endpointVideos = Settings.API_ENDPOINT_SERCH_VIDEOS_OLDER + this.getLastVideosID()
            if(this.props.search === true) {
                endpointVideos = Settings.API_ENDPOINT_SERCH_VIDEOS_OLDER  + this.getLastVideosID() + '?q=' + this.props.urlParams.query
            }
            this.ajax(Settings.API_URL + endpointVideos,{},"GET", null, (data) => {
                let olderImagesResultset = this.props.getManagerState().videosResultset
                olderImagesResultset = olderImagesResultset.concat(data.items)
                this.props.setManagerState({videosResultset: olderImagesResultset, loadingVideos: false}, () => {
                    this.mergeMedia()
                })
            })
        })

    }
    mergeMedia() 
    {
        let media = this.props.getManagerState().imagesResultset
        media = media.concat(this.props.getManagerState().videosResultset)
        this.props.setManagerState({mediaResultset: media})
    }

    getSearchVideos(endpoint) {
        this.props.setManagerState({videosResultset: [], currentPagination: null, loadingVideos: true}, () => {
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({videosResultset: data.items, currentPagination: data.pagination, loadingVideos: false})
            })
        })
    }


    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            language: that.props.getManagerState().language,
            history: that.props.history
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            getSearchVideos: (endpoint) => {
                this.getSearchVideos(endpoint)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            }
        }).setComponentsConstants('ImageContainer', {
            language: that.props.getManagerState().language,
        }).setComponentsMethods('ImageContainer', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        }).setComponentsConstants('SearchAllImages', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchAllImages', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            getSearchVideos: (endpoint) => {
                this.getSearchVideos(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            }
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'flex',
              flexWrap: 'wrap',
              margin: '80px auto',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            paper: {
              margin: '10px auto',
              padding: 10,
              width: '100%',
            },
            formContainer: {
              margin: '0 auto'
            },
            rootImageList: {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                width:"100%"
              },
              imageList: {
                width: '100%',
                height: 'auto',
              },
          };
        
        this.loadComponentsProps()    

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('/')
        paginationPattern[0] = '/page/{{page}}'+ paginationPattern[0]
        paginationPattern = paginationPattern.join('/')
        
        return <div ref={this.refDiv} style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <Grid container>

                    {
                                this.getQueryParams('videos') === '1'?
                                <SearchAllVideos {...this.buildComponentProps('SearchAllImages')} />
                                :
                                <SearchAllImages {...this.buildComponentProps('SearchAllImages')} />

                    }
                        <div style={{margin:'0 auto'}}>
                           <a href={this.setQueryParams('videos', 0)}> <Button startIcon={<PhotoLibraryIcon />} color="secondary" variant={this.getQueryParams('videos') === '1' ?'text' : 'outlined'} >{this.props.getManagerState().language.LANG_IMAGES}</Button></a>
                            <a href={this.setQueryParams('videos', 1)}><Button startIcon={<VideoLibraryIcon />} color="secondary" variant={this.getQueryParams('videos') === '1' ? 'outlined' : 'text'}> {this.props.getManagerState().language.LANG_VIDEOS}</Button></a>
                        </div>
                        <div style={classes.rootImageList}>

                            {
                                this.getQueryParams('videos') === '1'?
                                <React.Fragment>
                                    { this.props.getManagerState().videosResultset.map((el, idx) => {
                                            return <VideoContainer {...this.buildComponentProps('ImageContainer')} element={el} key={idx} />
                                        })
                                    }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <ImageList rowHeight={180} style={classes.imageList}>
                                        { this.props.getManagerState().imagesResultset.map((el, idx) => {
                                                return <ImageComponentListItem {...this.buildComponentProps('ImageContainer')} element={el} key={idx} />
                                            })
                                        }
                                    </ImageList>
                                </React.Fragment>
                        }                        
                        </div>
                    </Grid>
                    {
                            this.props.getManagerState().loadingImages || this.props.getManagerState().loadingVideos ?
                                <Grid item xs={12} lg={12} md={12}>
                                    <LoadingComponent />
                                </Grid>
                            :
                            <React.Fragment>
                            <Grid item xs={12} lg={4} md={4}></Grid>
                            <Grid item xs={12} lg={4} md={4}>
                                {this.getQueryParams('videos') === '1'?
                                <Button className={classes.button} color="secondary" variant="contained" onClick={() => {this.getOlderVideos()}} fullWidth component="div" startIcon={<ExpandMoreIcon />}>
                                    {this.props.getManagerState().language.LANG_LOAD_MORE_VIDEOS}
                                </Button>
                                :
                                <Button className={classes.button} color="secondary" variant="contained" onClick={() => {this.getOlderImages()}} fullWidth component="div" startIcon={<ExpandMoreIcon />}>
                                    {this.props.getManagerState().language.LANG_LOAD_MORE_IMAGES}
                                </Button>
                                }

                            </Grid>
                            <Grid item xs={12} lg={4} md={4}></Grid>
                            </React.Fragment>
                        }
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}