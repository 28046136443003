import React from 'react';
import { Typography } from '@material-ui/core';

export default function MissionPageComponent(props) {
    
  return (<React.Fragment>
            <Typography variant="h3" component="h3">
                Misiunea noastră
            </Typography>
            <Typography variant="body1">
                <strong>Lexie Art</strong> este un marketplace care unesti pasionatii de arta de creatorii de fotografie. Acest proiect are ca obiectiv sustinerea fotografilor profesionisti, prin expunerea proiectelor  fotografice, catre doritorii si pasionatii de arta.
            </Typography>
            <Typography variant="body1">
                Un caracter puternic intelege necesitatea armoniei mediului inconjurator. Arta care ne inconjoara acasa, la birou sau in calatoriiloe noastre, reprezinta procesul de incarcare a bateriei spirituale.
            </Typography>
            <Typography variant="subtitle2">
                Fotografii prezenti pe acest site sunt cu siguranta un tren express fotografic al Romaniei, unde privitorul va regasi frumusetea intr-un contrast incitant.
            </Typography>
            <Typography variant="body1">
                Iubitorilor de arta, le oferim posibilitatea de a comanda un tablou 100% autentic, reflexia pura a mediului inconjurator, surprinsa de lentila optica a aparatului de fotografiat.
            </Typography>
      </React.Fragment>);
}