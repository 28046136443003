import color from '@material-ui/core/colors/blue';
export default function Style(theme) {
    const style = {
        root: {
          maxWidth: 345,
          margin: '8px auto'
        },
        media: {
          paddingTop: '56.25%', // 16:9
          height: 'auto',
          backgroundPosition: 'top',
          backgroundSize: 'contain'
        },
        expand: {
          transform: 'rotate(0deg)',
          marginLeft: 'auto',
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
          }),
        },
        image: {
          width:'100%',
          margin: "0 auto",
          zIndex: 200,
          boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px'
        },
        expandOpen: {
          transform: 'rotate(180deg)',
        },
        avatar: {
          backgroundColor: color[500],
        },
        buttonSave: {
          marginBottom: 10
        },
        buttonCancel: {
          marginBottom: 10
        },
        buttonDelete: {
          
        },
        userNameLink: {
          textDecoration: "none",
          color: color[500]
        },
        avatarLink: {
          textDecoration: "none"
        },
        rootImage: {
          maxWidth: 350,
          margin: '0 auto',
          padding: 8,
          overflow: "hidden",
        },
      }
    return style
}