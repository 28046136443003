import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {
    Link
  } from "react-router-dom";
import color from '@material-ui/core/colors/teal';
export default class FooterComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            history: props.history,
            user: props.getManagerState().user,
        }
    }

    componentDidMount() {
        super.componentDidMount()
    }
    loadComponentsProps() {
    }
    getRender() {

        let classes = {
            root: {
              display: 'block',
              margin: '70px auto',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            link: {
                color: color[500],
                textDecoration: 'none',
                margin: '8px',
                display: 'block'
            },
            ul : {
                listStyle: 'none',
            }
          };
        this.loadComponentsProps()
        
        return <div style={classes.root} id="rootComponent">
                    <Divider />
                    <Grid container>
                        <Grid item xs={12} md={12} lg={4}>
                            <ul style={classes.ul}>
                                <li>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                            <ul style={classes.ul}>
                                <li>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                            <ul style={classes.ul}>
                                <li>
                                </li>
                                <li>   
                                </li>
                            </ul>                      
                        </Grid>
                    </Grid>
                </div>
    }
}
