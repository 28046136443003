import color from '@material-ui/core/colors/green';

export default function Style(theme) {
    const style = {
      root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        width: "100%"
      },
      input: {
        marginLeft: theme.spacing(1),
        flex: 1,
      },
      iconButton: {
        padding: 10,
      },
      divider: {
        height: 28,
        margin: 4,
      },
      img: {
        maxWidth: 350,
        width: '100%'
      },
      imgContainer: {
        maxWidth: 350,
        borderRadius: 10,
        margin: 8,
        display: "inline-block"
      },
      rootAddImage: {
        
      }
    }
    return style
}