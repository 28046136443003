import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
    Link
  } from "react-router-dom";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
const useStyles = makeStyles({
  root: {
    padding: 0,
    margin: '10px 0',
    width: 'auto'
  },
  button: {
      margin: '5px',
      display: "inline-block",
      textDecoration: "none"
  },
});

export default function UsePagination(props) {
  const classes = useStyles();

  const componentMethods = {
      ...props.componentMethods
  }

    const elements = []
    if(props.pagination) {
        const startPagination = props.pagination.current > 3 ? props.pagination.current - 3 : 1
        const endPagination = props.pagination.current > props.pagination.last - 3 ? props.pagination.last : props.pagination.current +  3
        for (let i = startPagination; i <= endPagination; i++) {
            elements.push(<Link key={i} className={classes.button} onClick={()=>{componentMethods.changePage(i)}} to={props.pattern.replace('{{page}}', i)}>
                <Button variant={props.pagination.current === i ? "contained" : "outlined"} color="secondary" component="span">
                    {i}
                </Button>
        </Link>)
        }
    }
  return (
      <div className={classes.root}>
          {props.pagination ?
          <Link  className={classes.button} onClick={()=>{componentMethods.changePage(props.pagination.first)}} to={props.pattern.replace('{{page}}', props.pagination.first)}>
            <Button variant="outlined" color="secondary" component="span">
               <FirstPageIcon />
            </Button>
        </Link>
        :
        null
        }
        {
            props.pagination && props.pagination.last > 1 && props.pagination.current > 1 ?
            <Link className={classes.button} onClick={()=>{componentMethods.changePage(props.pagination.current - 1)}} to={props.pattern.replace('{{page}}', props.pagination.current - 1)}>
                <Button variant="outlined" color="secondary" component="span">
               <NavigateBeforeIcon />
            </Button>
            </Link>
            :
            null
        }
        {
        elements.map((e, i) =>{
                return e
            })
        }
        {
            props.pagination && props.pagination.last > 1 && props.pagination.current < props.pagination.last ?
            <Link  className={classes.button} onClick={()=>{componentMethods.changePage(props.pagination.current + 1)}} to={props.pattern.replace('{{page}}', props.pagination.current + 1)}>
            <Button variant="outlined" color="secondary" component="span">
               <NavigateNextIcon />
            </Button>
            </Link>
            :
            null
        }
        {props.pagination ?
        <Link  className={classes.button} onClick={()=>{componentMethods.changePage(props.pagination.last)}} to={props.pattern.replace('{{page}}', props.pagination.last)}>
            <Button variant="outlined" color="secondary" component="span">
               <LastPageIcon />
            </Button>
        </Link>
        :
        null}
      </div>
  );
}