import React from "react";
import MediaQueryLg from "./MediaQueryLg";
import MediaQueryMd from "./MediaQueryMd";
import MediaQuerySm from "./MediaQuerySm";
import MediaQueryXl from "./MediaQueryXl";
import MediaQueryXs from "./MediaQueryXs";


function MediaQuery(props) {
    return <React.Fragment>
        <MediaQueryLg content={props.lg} />
        <MediaQueryMd content={props.md} />
        <MediaQuerySm content={props.sm} />
        <MediaQueryXl content={props.xl} />
        <MediaQueryXs content={props.xs} />
    </React.Fragment>
  }
  
  export default MediaQuery;