import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Style from './Styles/ImageContainerStyle';
import Settings from '../../Settings/Settings'
import CreateIcon from '@material-ui/icons/Create';
import Moment from 'react-moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'moment/dist/locale/ro';
import {
  Link
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import DeleteVideoDialog from './DeleteVideoDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import CardActionArea from '@material-ui/core/CardActionArea';
import LinkIcon from '@material-ui/icons/Link';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Chip from '@material-ui/core/Chip';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import SendIcon from '@material-ui/icons/Send';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import { useRef } from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function VideoContainer(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [myPictureLocation, setMyPictureLocation] = React.useState(props.element.videosLocationName)
  const [myPictureDescription, setMyPictureDescription] = React.useState(props.element.videosDescription)
  const [myPicturePublished, setMyPicturePublished] = React.useState(parseInt(props.element.videosPublished))
  const [initialMyPictureLocation, setInitialMyPictureLocation] = React.useState(props.element.videosLocationName)
  const [initialMyPictureDescription, setInitialMyPictureDescription] = React.useState(props.element.videosDescription)
  const [initialMyPicturePublished, setInitialMyPicturePublished] = React.useState(parseInt(props.element.videosPublished))
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
  const [showAddToCart, setShowAddToCart] = React.useState(false)
  const [likes, setLikes] = React.useState({})
  const [showImageLoader, setShowImageLoader] = React.useState(true)
  const [comments, setComments] = React.useState([])
  const [imagesCommentsPagination, setImagesCommentsPagination] = React.useState({})
  const [commentText, setCommentText] = React.useState("")
  const [commentFirstName, setCommentFirstName] = React.useState((props.componentMethods.getLocalStorage("user")?props.componentMethods.getLocalStorage("user").usersFirstName:""))
  const [commentLastName, setCommentLastName] = React.useState((props.componentMethods.getLocalStorage("user")?props.componentMethods.getLocalStorage("user").usersLastName:""))
  const [showAddComments, setShowAddComments] = React.useState(false)
  const [imagePromoted, setImagePromoted] = React.useState(false)
  const [use360p, setUse360p] = React.useState(true)
  const [use720p, setUse720p] = React.useState(false)
  const [use1080p, setUse1080p] = React.useState(false)
  const videoRef = useRef(null);

  useEffect(() => {
    let img = document.createElement('img');
    img.src = Settings.API_URL + Settings.API_ENDPOINT_FILE + props.element.imagesPath+ '?thumbsWidth=800';
  }, [])
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const getPictureUrl = (imagePath) => {
    if(window.location.pathname === '/') {
      return '/image/' + imagePath
    } else {
      let concatString = '/'
      if (window.location.pathname.slice(-1) === '/') {
        concatString = ''
      }
      return window.location.pathname + concatString + 'image/' + imagePath
    }
  }
  const getResolutionPlaying = () =>
    {
      if (use360p)
        return '360'
      else if (use720p)
        return '720'
      else if (use1080p)
        return '1080'
    }
  const handleChangeImagesPublished = (event) => {
    setMyPicturePublished(event.target.checked);
  };
  const setInitialValues = () => {
    setMyPictureLocation(initialMyPictureLocation)
    setMyPictureDescription(initialMyPictureDescription)
    setMyPicturePublished(initialMyPicturePublished)
  }
  const updateInitialValuesWithMyPicture = () => {
    setInitialMyPictureDescription(myPictureDescription)
    setInitialMyPictureLocation(myPictureLocation)
    setInitialMyPicturePublished(myPicturePublished)
  }
  const getImagesLikesAction = (force = false) => {
    if (props.element.counts && !force)
    {
      setLikes(props.element.counts)
    } else {
      componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_IMAGES_LIKES + '/' + props.element.imagesID, {},'GET',null, (data) => {
        setLikes(data.items)
      })
    }
  }
  const postComment = () => {
    let postObject = {imagesCommentsImagesID:props.element.imagesID, imagesCommentsText: commentText, imagesCommentsFirstName: commentFirstName, imagesCommentsLastName: commentLastName}
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_COMMENTS,postObject,'POST',"etc", () => {
          getImageComments()
          setCommentText("")
          getImagesLikesAction(true)
        })
  }

  const postProfilePicture = () => {
    let postObject = {usersProfileSettingsName:'profilePicture', usersProfileSettingsValue: props.element.imagesPath}
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_USERS_PROFILE_SETTINGS,postObject,'POST',null, () => {
        })
  }

  const postCoverPicture = () => {
    let postObject = {usersProfileSettingsName:'coverPhoto', usersProfileSettingsValue: props.element.imagesPath}
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_USERS_PROFILE_SETTINGS,postObject,'POST',null, () => {
        })
  }

  const postImagesPromoted = () => {
    setImagePromoted(true)
    let postObject = {imagesPromotedImagesID:props.element.imagesID}
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_IMAGES_PROMOTED,postObject,'POST',null, () => {
        })
  }
  const getImageComments = () => {

    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_IMAGES_COMMENTS_BY_PATH + '/' + props.element.imagesPath, {},'GET',null, (data) => {
      setComments(data.items)
      setImagesCommentsPagination(data.pagination)
      setShowAddComments(true)
    })
  }
  const getMoreComments = () => {
    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_IMAGES_COMMENTS_BY_PATH + '/' + props.urlParams.image + '?page=' + imagesCommentsPagination.next,{},'GET',"etc", (data) => {
        let existingComments = comments
        existingComments = existingComments.concat(data.items)
        setImagesCommentsPagination(data.pagination)
        setComments(existingComments)
    })
}
  const postImagesLikesAction = () => {
    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_IMAGES_LIKES + '/' + props.element.imagesID, {},'POST',null, (data) => {
       getImagesLikesAction(true)
    })
  }

  const deleteImagesLikesAction = () => {
    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_IMAGES_LIKES + '/' + props.element.imagesID, {},'DELETE',null, (data) => {
       getImagesLikesAction(true)
    })
  }
  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  const saveImage = () => {
    const data = {
        videosDescription: myPictureDescription,
        videosLocationName: myPictureLocation,
        videosPublished: myPicturePublished === false ? 0 : 1
    }
    updateInitialValuesWithMyPicture();
    componentMethods.updateVideo(data, props.element.videosID)
  }

  const set720 = () =>
  {
    setUse360p(false)
    setUse720p(true)
    setUse1080p(false)
    videoRef.current.src = Settings.API_URL + Settings.API_ENDPOINT_VIDEO_FILE + props.element.videosPath + '?thumbsWidth=720'
  }
  const set1080 = () =>
  {
    setUse360p(false)
    setUse720p(false)
    setUse1080p(true)
    videoRef.current.src = Settings.API_URL + Settings.API_ENDPOINT_VIDEO_FILE + props.element.videosPath + '?thumbsWidth=1080'
  }

  const set360 = () =>
  {
    setUse360p(true)
    setUse720p(false)
    setUse1080p(false)
    videoRef.current.src = Settings.API_URL + Settings.API_ENDPOINT_VIDEO_FILE + props.element.videosPath+ '?thumbsWidth=360'
  }
  return <Grid item xs={12} lg={3} md={3} id={'product-' + props.element.imagesID}>
            <div className={classes.rootImage}>
                <Paper>
            <DeleteVideoDialog videosID={props.element.videosID} componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} setOpenDeleteDialog={setOpenDeleteDialog} openDeleteDialog={openDeleteDialog} />
                {props.autoplay ? 
                  <video ref={videoRef} autoPlay id="videoplayer" muted poster={Settings.API_ENDPOINT_PATH_VIDEO_URL+ '?path=' + props.element.videosPath + '.png'}  className={classes.image} src={Settings.API_URL + Settings.API_ENDPOINT_VIDEO_FILE + props.element.videosPath+ '?thumbsWidth=360'} alt={props.componentConstants.language.LANG_WEBSITE_MOTO + props.componentConstants.language.LANG_IMAGE_PRE_TITLE + " " + props.element.usersFirstName + " " + props.element.usersLastName + " "+props.element.videosLocationName + " " + props.element.videoDescription}  type="video/mp4" controls />
                  :
                  <video ref={videoRef}  className={classes.image} poster={Settings.API_ENDPOINT_PATH_VIDEO_URL + '?path=' + props.element.videosPath + '.png'} src={Settings.API_URL + Settings.API_ENDPOINT_VIDEO_FILE + props.element.videosPath+ '?thumbsWidth=360'} alt={props.componentConstants.language.LANG_WEBSITE_MOTO + props.componentConstants.language.LANG_IMAGE_PRE_TITLE + " " + props.element.usersFirstName + " " + props.element.usersLastName + " "+props.element.videosLocationName + " " + props.element.videoDescription}  type="video/mp4" controls />
                }
                <div style={{margin: 8}}>
                    <IconButton onClick={() => {
                    navigator.clipboard.writeText(Settings.SOCIAL_MEDIA_SETTING_SITE_URL + 'video/' + props.element.videosPath + '?resolution=' + getResolutionPlaying())
                    componentMethods.addError(componentConstants.language.LANGUAGE_LINK_COPPIED_TO_CLIPBOARD)
                    }}><ShareIcon /></IconButton>
                      <Button style={{margin: 8}} variant={use360p ? "contained": "outlined"} onClick={set360}>360</Button>
                      <Button style={{margin: 8}} variant={use720p ? "contained" : "outlined"} onClick={set720}>720</Button>
                      <Button style={{margin: 8}} variant={use1080p ? "contained": "outlined"} onClick={set1080}>1080</Button>
                      <a href={Settings.SETTING_SITE_URL + '/' + Settings.SETTING_VIDEO_URL + '/' + props.element.videosPath}><Button style={{margin: 8}} variant={use1080p ? "contained": "outlined"} onClick={set1080}><PlayArrowIcon /></Button></a>
                </div>
                <div style={{padding: 8}}>
                      {
                      comments.map((item, index) => {
                          return <Paper variant="outlined" className={classes.commentPager} key={index}>
                                    <Typography variant="subtitle2" gutterBottom>{(item.imagesCommentsFirstName ? item.imagesCommentsFirstName : componentConstants.language.LANG_ANONYMOUS) + ' ' + (item.imagesCommentsLastName ? item.imagesCommentsLastName : componentConstants.language.LANG_ANONYMOUS)}:</Typography>
                                    <Typography variant="subtitle1" gutterBottom>{item.imagesCommentsText}</Typography>
                                </Paper>
                        })
                      }
                      {imagesCommentsPagination.current < imagesCommentsPagination.last ?
                        <Button onClick={getMoreComments}>{componentConstants.language.LANG_LOAD_MORE_COMMENTS}</Button>
                      :
                      null}
                    </div>
                    {showAddComments?
                    <div style={{padding: 8}}>
                      <Typography variant="h5" component="h5">{componentConstants.language.LANG_ADD_COMMENTS}</Typography>
                    <Paper>
                  <TextField
                      id="filled-multiline-flexible"
                      label={componentConstants.language.LANG_FIRST_NAME}
                      variant="filled"
                      value={commentFirstName}
                      onChange={(e) => {setCommentFirstName(e.target.value)}}
                      fullWidth
                      color="secondary"
                      size="small"
                  />
                  <TextField
                      id="filled-multiline-flexible"
                      label={componentConstants.language.LANG_LAST_NAME}
                      variant="filled"
                      value={commentLastName}
                      onChange={(e) => {setCommentLastName(e.target.value)}}
                      fullWidth
                      color="secondary"
                      size="small"
                  />
                  <TextField
                      id="filled-multiline-flexible"
                      label={componentConstants.language.LANG_COMMENT}
                      multiline
                      maxRows={4}
                      rows={4}
                      variant="filled"
                      value={commentText}
                      onChange={(e) => {setCommentText(e.target.value)}}
                      fullWidth
                      color="secondary"
                      size="small"
                  />
                  <Button fullWidth style={classes.sendCommentButton} onClick={postComment} endIcon={<SendIcon />} variant="contained" color="secondary">{componentConstants.language.LANG_SEND_COMMENT}</Button>
                  </Paper>
                  </div>
                    :
                    null}
                { props.canEdit ?
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <CreateIcon />
                  </IconButton>
                  :
                  null
                  }
                  <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.collapse}>
                    <Typography paragraph>
                    <TextField fullWidth={true} id="standard-basic" label={props.componentConstants.language["LANG_UPLOAD_VIDEO_LOCATION_NAME"]} value={myPictureLocation} onChange={(e) => {setMyPictureLocation(e.target.value)}} />
                    </Typography>
                    <Typography paragraph>
                    <TextField fullWidth={true} id="standard-basic" label={props.componentConstants.language["LANG_UPLOAD_VIDEO_DESCRIPTION"]} value={myPictureDescription} onChange={(e) => {setMyPictureDescription(e.target.value)}} />
                    </Typography>
                    <FormControlLabel fullWidth={true} className={classes.formFullWidth}
                  control={<Checkbox checked={myPicturePublished} onChange={handleChangeImagesPublished} />}
                  label={props.componentConstants.language["LANG_VIDEO_PUBLISHED"]}
                />
                    <Button fullWidth={true} variant="contained" color="secondary" className={classes.buttonSave} onClick={saveImage}>
                        {props.componentConstants.language.LANG_SAVE}
                    </Button>
                    <Button fullWidth={true} variant="outlined" color="secondary" className={classes.buttonCancel} onClick={setInitialValues}>
                        {props.componentConstants.language.LANG_CANCEL}
                    </Button>
                    <Button fullWidth={true} variant="contained" color="primary" className={classes.buttonDelete} onClick={(e)=>{setOpenDeleteDialog(true)}} startIcon={<DeleteIcon />}>
                        {props.componentConstants.language.LANG_DELETE}
                    </Button>
                </Collapse>
            </Paper>
            </div>
          </Grid>
}