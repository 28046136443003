import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from './Styles/ImagePopupStyle';
import Settings from '../../Settings/Settings'
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import $ from "jquery";
const useStyles = makeStyles((theme) => (Style(theme)));

export default function ImagePopup(props) {
  const classes = useStyles();
  const [image, setImage] = React.useState(null)
  useEffect(() => {
    getImageDetailsAction()
  }, []);

  const getImageDetailsAction = () => {
    $.ajax({
        url: Settings.API_URL + Settings.API_ENDPOINT_UPLOAD_IMAGES + '/' + props.urlParams.image,
        type: 'GET',
        data: {},
        success: function (data) {
              if(data.items.length)
                setImage(data.items[0])
        },
        error: function (request, status, error) {
          let errors = componentMethods.getManagerState().errors
          errors.push(componentConstants.language["LANG_ERROR_COULD_NOT_LOAD_IMAGE"])
          componentMethods.setManagerState({errors:errors})
        },
        dataType: "json",
        cache: false,
        contentType: false,
        processData: false
    });
  }
  const closeImage = () => {
      const pathNameSplit = window.location.pathname.split("image")
      props.changeRoute(pathNameSplit[0])
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const componentConstants = {
      ...props.componentConstants,
      language: props.getManagerState().language
  }

  const componentMethods = {
      ...props.componentMethods,
      handleClosePopover: () => {
        handleClose()
      },
      setManagerState: (stateObject, callback) => {
        props.setManagerState(stateObject, callback)
      },
      getManagerState: () => {
        return props.getManagerState()
      },

    getLocalStorage: (storageName = 'state') => {
      // We need the try block because user may not permit our accessing localStorage.
      try {
          const serializedState = localStorage.getItem(storageName);
          if (serializedState === null) { // The key 'state' does not exist.
              return undefined;             // Let our reducer initialize the app.
          }

          return JSON.parse(serializedState)

      } catch (error) {
          return undefined // Let our reducer initialize the app.
      }
  },

  ajax: (url, 
    dataObject = {}, 
    method, stateValue = '', 
    callback = () => {}, 
    headers = {}, 
    setState = true, 
    catchCallback = () => {}) => {
    // adauga o fct de callback pe eroare (catchCallback) pe care o executi in catch
    let sendDataObj = {};
    let dataURLSearchParams = new URLSearchParams();
    for (let pDO in dataObject) {
        if (dataObject.hasOwnProperty(pDO)) {
            sendDataObj[pDO] = dataObject[pDO];
            dataURLSearchParams.append(pDO, dataObject[pDO]);
        }
    }

    let stateSet = {};
    let requestObject = {
        method: method,
        mode: "cors",
        cache: "no-cache",
        headers: headers,
    };

    switch(method) {
        case 'GET':
            break;
        default:
            requestObject.body = dataURLSearchParams
            break;
    }

    fetch(url, requestObject).then((response) => {
        const res = response.json();
        return res;
    }).then((data) => {
        if (data.success === true || data.httpStatusCode === 200) {
            stateSet[stateValue] = data;
            if (setState) {
                this.setState(stateSet, () => {
                    callback(data);
                })
            } else {
                callback(data)
            }
        } else {
            catchCallback(data);
        }
    }).catch((error) => {
    });
}

  }

  const componentProps = {
      ...props.componentProps
  }
  return (
    <div className={classes.root}>
            <div className={classes.img} style={{position:"absolute", left:0, top:0 }} >
                <img src={Settings.API_URL + Settings.API_ENDPOINT_FILE + props.urlParams.image} style={{maxWidth: '100%', maxHeight: '100%', margin:'0 auto', display: "block"}} />
                  <Button onClick={closeImage} variant="contained" color="secondary" className={classes.button}>
                  <ArrowBackIcon />{componentConstants.language.LANG_BACK}
                </Button>          
            </div>
    </div>
  );
}