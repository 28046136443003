import React from "react";
import LANGUAGES from '../Languages/Languages'
import lang from "../Languages/Languages";
import Theme from '../Themes/Theme'
import Grid from '@material-ui/core/Grid';

class CoreComponent extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            render: true,
            language:  LANGUAGES[0],
            errors: {
                fields: {},
                message: ''
            },

        }
        this.componentsProps = {}

        this.responses = {
            success: {
                success: true,
                data: [],
                status: 200
            },
            forbidden: {
                success: false,
                errorMessages: [],
                status: 404
            },
            invalid: {
                success: false,
                errorMessages: [],
                validationMessages: [],
                status: 403
            },
            error: {
                success: false,
                errorMessages: [],
                status: 500
            }
        }
        if(props.Theme)
            this.muiTheme = props.Theme
        else
            this.muiTheme = Theme
    }
    componentDidMount() {
        this.useTheme(this.muiTheme)
    }

    setComponentsProps(componentName, componentProps) {
        this.getComponentsProps(componentName)
        this.componentsProps[componentName].componentProps = componentProps
        return this
    }
    setComponentsMethods(componentName, componentMethods) {
        this.getComponentMethods(componentName)
        this.componentsProps[componentName].componentMethods = componentMethods
        return this
    }
    setComponentsConstants(componentName, componentConstant) {
        this.getComponentConstants(componentName)
        this.componentsProps[componentName].componentConstants = componentConstant
        return this
    }
    getComponentsProps(componentName) {
        if(this.componentsProps[componentName] === undefined)
            this.componentsProps[componentName] = {}
        return this.componentsProps[componentName].componentProps
    }
    getComponentMethods(componentName) {
        if(this.componentsProps[componentName] === undefined)
            this.componentsProps[componentName] = {}
        return this.componentsProps[componentName].componentMethods
    }
    getComponentConstants(componentName) {
        if(this.componentsProps[componentName] === undefined)
            this.componentsProps[componentName] = {}
        return this.componentsProps[componentName].componentConstants
    }
    buildComponentProps(componentName) {
        return {
            componentProps: this.getComponentsProps(componentName),
            componentMethods: this.getComponentMethods(componentName),
            componentConstants: this.getComponentConstants(componentName),
        }
    }
    loadComponentsProps(reload = false) {
        if (reload) {
            this.refresh()
        }
    }

    async ajax (url, 
        dataObject = {}, 
        method, stateValue = '', 
        callback = () => {}, 
        headers = {}, 
        setState = true, 
        catchCallback = () => {},
        errorCallback = () => {}, ignoreUser = false) {
        // adauga o fct de callback pe eroare (catchCallback) pe care o executi in catch
        let sendDataObj = {};
        let dataURLSearchParams = new URLSearchParams();
        for (let pDO in dataObject) {
            if (dataObject.hasOwnProperty(pDO)) {
                sendDataObj[pDO] = dataObject[pDO];
                dataURLSearchParams.append(pDO, dataObject[pDO]);
            }
        }
        if (!headers["X-Account-Token"])
        {
            // If your expected result is "http://foo.bar/?x=1&y=2&x=42"
            if (this.getLocalStorage('user') && !ignoreUser)
            {
                headers["X-Account-Token"] = this.getLocalStorage('user').tokensHash
            }
        }
        let stateSet = {};
        let requestObject = {
            method: method,
            mode: "cors",
            cache: "no-cache",
            headers: headers,
        };

        switch(method) {
            case 'GET':
                break;
            default:
                requestObject.body = dataURLSearchParams
                break;
        }
        
        fetch(url.toString(), requestObject).then((response) => {
            const res = response.json();
            return res;
        }).then((data) => {
            if (data.success === true || data.httpStatusCode === 200) {
                stateSet[stateValue] = data;
                if (setState) {
                    this.setState(stateSet, () => {
                        callback(data);
                    })
                } else {
                    callback(data)
                }
            } else {
                catchCallback(data);
            }
        }).catch((error) => {
            errorCallback()
        });
    }

    setStateFromProps(state) {
        if(state !== undefined)
            this.setState(state);
    }

    getQueryParams (param) {
        const urlParams = new URLSearchParams(window.location.search);
        const queryParam = urlParams.get(param);
        return queryParam;
    }

    getDataStructure () {
        return {
            success: false,
            status: 200,
            data: []
        }
    }
    getTranslation(language, translationKey) {
        return lang[language][translationKey]
    }
    getAllTranslations(language) {
        return lang[language];
    }
    setLanguage(lang, callback = () => {} ) {
        if(typeof lang === "number")
            this.setState({lang: LANGUAGES[lang]}, callback)
        else
            this.setState({lang: lang}, callback)
    }
    setQueryParams (paramName, paramValue) {
        const url = new URL(window.location.href);

        url.searchParams.set(paramName, paramValue);

        return url
    }
    setStateTextFeldValue(value, name) {
        let setObject = {}
        setObject[name] = value
        this.setState(setObject)
    }
    useTheme(theme) {
        this.muiTheme = theme
        return this
    }
    render() {
        return <React.Fragment>
                { this.getRender() }
            </React.Fragment>
    }

    historyPush(path) {
        this.props.history.push(path)
    }

    getRender() {
        return <div>This is the core component. This component should be inherited</div>
    }

    getRenderReponsiveContainer(content) {
        this.setComponentsProps('reponsiveGridItemContainer', {
            xs: 12,
            lg: 6,
            md: 6
        }).setComponentsProps('responsiveGridItemMargin', {
            xs: 0,
            lg: 3,
            md: 3
        })
        return <Grid container>
            <Grid {...this.getComponentsProps('responsiveGridItemMargin')}>
            </Grid>
            <Grid {...this.getComponentsProps('responsiveGridItemContainer')}>
                {content}
            </Grid>
                <Grid {...this.getComponentsProps('responsiveGridItemMargin')}>
            </Grid>
        </Grid>
    }

    getLocalStorage(storageName = 'state') {
        // We need the try block because user may not permit our accessing localStorage.
        try {
            const serializedState = localStorage.getItem(storageName);
            if (serializedState === null) { // The key 'state' does not exist.
                return undefined;             // Let our reducer initialize the app.
            }

            return JSON.parse(serializedState)

        } catch (error) {
            return undefined // Let our reducer initialize the app.
        }
    }

    saveLocalStorage(storageObject, storageName) {
        try {
            localStorage.setItem(storageName, JSON.stringify(storageObject))
        } catch (error) {
            console.log(error)
        }
    }
    refresh() {
        this.loadComponentsProps();
        this.forceUpdate();
    }

    addError(error, invalidFields = {}) {
        let errors = this.props.componentMethods.getManagerState().errors
        errors.push(error)
        this.props.componentMethods.setManagerState({errors: errors, ...invalidFields})
    }

    addErrorMain(error, invalidFields = {}) {
        let errors = this.props.getManagerState().errors
        errors.push(error)
        this.props.setManagerState({errors: errors, ...invalidFields})
    }

    isLoggedInUser(callback = () => {}) 
    {
        if (this.getLocalStorage('user')) {
            this.props.componentMethods.setManagerState({'user': this.getLocalStorage('user')},callback)
            return true
        } else
            return false
    }

    hasAccess(accessLevel) {
        if (accessLevel === 99) 
            return true;
        const user = this.getLocalStorage("user")
        if(!user)
            return false;
        for(let i in user.usersAccessLevels) {
            if (accessLevel >= parseInt(user.usersAccessLevels[i].accessLevelsLevel))
                return true
        }
        return false
    }

    hasExactAccess(accessLevel) {
        if (accessLevel === 99) 
            return true;
        const user = this.getLocalStorage("user")
        if(!user)
            return false;
        for(let i in user.usersAccessLevels) {
            if (accessLevel === parseInt(user.usersAccessLevels[i].accessLevelsLevel))
                return true
        }
        return false
    }
}


export default CoreComponent;
