import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
export default function DeleteImageDialog(props) {

  const componentConstants = {
    ...props.componentConstants
  }
  
  const handleClose = () => {
    props.setOpenDeleteDialog(false);
  };

  return (
    <div>
      <Dialog
        open={props.openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{componentConstants.language.LANG_DELETE_IMAGE_MESSAGE_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {componentConstants.language.LANG_DELETE_IMAGE_MESSAGE}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
          {componentConstants.language.LANG_CANCEL}
          </Button>
          <Button onClick={(e) => { props.componentMethods.deleteImageAction(props.imageID); handleClose()}} color="secondary" variant="contained" autoFocus>
          {componentConstants.language.LANG_DELETE}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}