import React from 'react';
import Button from '@material-ui/core/Button';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'

export default class UserOrderComponent extends CoreComponent {

  constructor(props) {
      super(props);
      this.loadComponentsProps()
      this.useTheme(Theme)
      this.basicComponentConstants = {
          language: props.getManagerState().language,
          history: props.history,
          user: props.getManagerState().user,
      }
  }

  componentDidMount() {
      super.componentDidMount()
  }
   getRender() {

        let classes = {
              root: {
                position: "fixed",
                left: "8px",
                bottom: "8px",
                maxWidth: 600,
                '& > * + *': {
                  marginTop: "16px",
                },
              },
            }
        this.loadComponentsProps()
        if(this.getLocalStorage('cookiesPolicy')) {
          return null
        } else {
          return <div style={classes.root}>
                  <SnackbarContent
                    message={this.props.getManagerState().language.LANG_COOKIE_SNACKBAR_TEXT}
                    action={
                      <Button onClick={() => {this.saveLocalStorage(true,"cookiesPolicy"); this.props.setManagerState({cookiePolicy: true})}} color="secondary" variant="contained">
                    {this.props.getManagerState().language.LANG_ACCEPT_COOKIE_POLICY}
                      </Button>}
                  />
                </div>          
        }
    }
}