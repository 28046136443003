import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import TitleAppBar from './MaterialComponents/TitleAppBar';
import Theme from '../../../../Themes/ThemeArtLexie'
import settings from '../../Settings/Settings';
export default class AppbarComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.wrapperPopoverDiv = React.createRef();
    }

    componentDidMount() {
        if(! this.props.componentMethods.getManagerState().createAccountYears.items.length) {
            this.ajax(settings.API_URL + settings.API_ENDPOINT_REGISTER_YEAR,{},'GET','createAccountYears', (data) => {this.setCreateAccountYears(data)})
        }
        if (! this.props.componentMethods.getManagerState().counties.items.length) {
            this.ajax(settings.API_URL + settings.API_ENDPOINT_COUNTIES,{},'GET','counties', (data) => {this.setAddressCounties(data)})
        }

        if (! this.props.componentMethods.getManagerState().addresses.items.length) {
            if(this.getLocalStorage('user')) {
                this.ajax(settings.API_URL + settings.API_ENDPOINT_ADDRESS + '?accountToken=' + this.props.componentMethods.getLocalStorage('user').tokensHash,{},'GET','addresses', (data) => {this.setUserAddresses(data)})
            }
        }
        this.isLoggedInUser(() => {this.setUserData(this.props)})
        if(!this.getLocalStorage('shoppingCart')) {
            this.postShoppingCartAction()
        }
         if (this.getLocalStorage('shoppingCart') && this.getLocalStorage('shoppingCart').shoppingCartUsersID && !this.getLocalStorage('users')) {
            this.postShoppingCartAction()
        }
        super.componentDidMount()
    }
    postShoppingCartAction() {
        const that = this
        let accessToken = ''
        if(this.getLocalStorage('user')) {
            accessToken = '?accountToken=' + this.getLocalStorage('user').tokensHash
        }
        this.ajax(settings.API_URL + settings.API_ENDPOINT_SHOPPINGCART + accessToken,{},'POST','shoppingCart', (data) => {
            this.saveLocalStorage(data.items[0], 'shoppingCart')
            that.getShoppingCartTotal()
            if (that.props.componentMethods.refreshCheckout)
                that.props.componentMethods.refreshCheckout()
        })
    }
    setUserData(props) {
        let user = null
        if(props) {
            user = props.componentMethods.getManagerState().user
        }
        if(user) {
            props.componentMethods.setManagerState({
                yourAccountUsersLastName: user.usersLastName, 
                yourAccountUsersFirstName: user.usersFirstName, 
                yourAccountUsersEmail: user.usersEmail,
                yourAccountUsersDateOfBirthYear:user.usersDateOfBirth.split('-')[0], 
                yourAccountUsersDateOfBirthMonth:user.usersDateOfBirth.split('-')[1], 
                yourAccountUsersDateOfBirthDay:user.usersDateOfBirth.split('-')[2], 
                yourAccountUsersSexID: user.usersUsersSexID,
                formInvalidYourAccountUsersFirstName: false,
                formInvalidYourAccountUsersLastName: false,
                formInvalidYourAccountUsersEmail: false,
                formInvalidYourAccountUsersDateOfBirth: false,
                formInvalidYourAccountUsersUsersSexID: false
            })
        }
    }

    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('TitleAppBar', {
            language: that.props.componentMethods.getManagerState().language,
            messages: that.props.componentMethods.getManagerState().messages,
            wrapperPopoverDiv: this.wrapperPopoverDiv,
            history: that.props.componentConstants.history,
            user: that.props.componentMethods.getManagerState().user,
            emptyAddress: {
                addressStreet: null,
                addressNumber: null,
                addressBlock: null,
                addressEntrance: null,
                addressFloor: null,
                addressApartment: null,
                addressCity: null,
                addressCountiesID: null,
                addressCountry: null,
                addressObservations: null,
                addressFirstName: null,
                addressLastName: null
            }
        }).setComponentsMethods('TitleAppBar', {
            setManagerState: (object, callback) => {
                that.props.componentMethods.setManagerState(object, callback)
            },
            getUserAddresses: () => {
                that.getUserAddresses()
            },
            addError: (errorMessage, stateObject) => {
                that.addError(errorMessage, stateObject)
            },
            refreshMainComponent: () => {
                that.props.componentMethods.refreshMainComponent()
            },
            getSearchImages: (endpoint) => {
                that.props.componentMethods.getSearchImages(endpoint)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            changeRoute: (route) => {
                that.props.componentMethods.changeRoute(route)
            },
            logInAction: () => {
                that.props.componentMethods.setManagerState({loadingLogin: true})
                let requestLogInObject = {
                    usersEmail: that.props.componentMethods.getManagerState().logInUsersEmail,
                    usersPassword:that.props.componentMethods.getManagerState().logInUsersPassword,
                }
                that.ajax(settings.API_URL + settings.API_ENDPOINT_LOGIN,requestLogInObject,'POST',null, (data) => {
                    that.setSuccessfulUsersLogIn(data); 
                    that.clearLogInForm(); 
                    if(that.getLocalStorage('shoppingCart')) {
                        that.mergeShoppingCart()
                    } else {
                        that.postShoppingCartAction(); 
                    }
                    that.getUserAddresses()
                }, {}, false, (data) => {that.setLogInErrors(data)}, () => {
                    that.props.componentMethods.setManagerState({loadingLogin: false})
                    this.addError(that.props.componentMethods.getManagerState().language['LANG_ERROR_ON_LOGIN'])
                })
            },
            setLogInErrors: (data) => {
                that.setLogInErrors(data)
            },
            mergeShoppingCart: () => {
                that.mergeShoppingCart()
            },
            postShoppingCartAction: () => {
                that.postShoppingCartAction()
            },
            updateAccountAction: () => {
                if(that.props.componentMethods.getManagerState().yourAccountUsersNewPassword && that.props.componentMethods.getManagerState().yourAccountUsersNewPassword !== that.props.componentMethods.getManagerState().yourAccountUsersNewPasswordConfirm) {
                    that.addError(that.props.componentMethods.getManagerState().language["LANG_ERROR_INVALID_PASSWORD_CONFIRM"], {formInvalidCreateAccountPassword: true, formInvalidCreateAccountPasswordConfirm: true})
                } else {
                    this.props.componentMethods.setManagerState({loadingYourAccount: true})
                    let requestUpdateAccountObject = {
                        usersEmail: that.props.componentMethods.getManagerState().yourAccountUsersEmail,
                        usersLastName: that.props.componentMethods.getManagerState().yourAccountUsersLastName,
                        usersFirstName: that.props.componentMethods.getManagerState().yourAccountUsersFirstName,
                        usersUsersSexID: that.props.componentMethods.getManagerState().yourAccountUsersSexID,
                        usersDateOfBirth: that.props.componentMethods.getManagerState().yourAccountUsersDateOfBirthYear + '-' + that.props.componentMethods.getManagerState().yourAccountUsersDateOfBirthMonth + '-' + that.props.componentMethods.getManagerState().yourAccountUsersDateOfBirthDay
                    }
                    if(that.props.componentMethods.getManagerState().yourAccountUsersNewPassword && that.props.componentMethods.getManagerState().yourAccountUsersNewPassword === that.props.componentMethods.getManagerState().yourAccountUsersNewPasswordConfirm) {
                        requestUpdateAccountObject.usersPasswordCheck = that.props.componentMethods.getManagerState().yourAccountUsersOldPassword
                        requestUpdateAccountObject.usersPassword = that.props.componentMethods.getManagerState().yourAccountUsersNewPassword
                    }

                    that.ajax(settings.API_URL + settings.API_ENDPOINT_UPDATE_PROFILE + '?accountToken=' + that.props.componentMethods.getManagerState().user.tokensHash,requestUpdateAccountObject,'PUT',null, (data) => {this.setSuccessfulUsersAccountUpdate(data)}, {}, false, (data) => {this.setUpdateAccountErrors(data)})
                }
            },
            setCreateAccountUsersFirstName: (value) => {
                that.props.componentMethods.setManagerState({createAccountUsersFirstName: value, formInvalidCreateAccountUsersFirstName: false})
            },
            setCreateAccountUsersLastName: (value) => {
                that.props.componentMethods.setManagerState({createAccountUsersLastName: value, formInvalidCreateAccountUsersLastName: false})
            },
            setCreateAccountUsersEmail: (value) => {
                that.props.componentMethods.setManagerState({createAccountUsersEmail: value, formInvalidCreateAccountUsersEmail: false})
            },
            setCreateAccountUsersPassword: (value) => {
                that.props.componentMethods.setManagerState({createAccountUsersPassword: value, formInvalidCreateAccountPassword: false})
            },
            setCreateAccountUsersPasswordConfirm: (value) => {
                that.props.componentMethods.setManagerState({createAccountUsersPasswordConfirm: value, formInvalidCreateAccountPasswordConfirm: false})
            },
            setCreateAccountUsersDateOfBirthYear: (value) => {
                that.props.componentMethods.setManagerState({createAccountUsersDateOfBirthYear:value, formInvalidCreateAccountUsersDateOfBirth: false})
            },
            setCreateAccountUsersDateOfBirthMonth: (value) => {
                that.props.componentMethods.setManagerState({createAccountUsersDateOfBirthMonth: value, formInvalidCreateAccountUsersDateOfBirth: false})
            },
            setCreateAccountUsersDateOfBirthDay: (value) => {
                that.props.componentMethods.setManagerState({createAccountUsersDateOfBirthDay: value, formInvalidCreateAccountUsersDateOfBirth: false})
            },
            setCreateAccountUsersSexID: (value) => {
                that.props.componentMethods.setManagerState({createAccountUsersSexID: parseInt(value), formInvalidCreateAccountUsersUsersSexID: false})
            },
            setUploadImagesLocationName: (value) => {
                that.props.componentMethods.setManagerState({uploadImagesLocationName: value, formInvalidUploadImagesLocationName: false})                    
            },
            setUploadImagesDescription: (value) => {
                that.props.componentMethods.setManagerState({uploadImagesDescription: value, formInvalidUploadImagesDescription: false})                    
            },
            setLogInUsersEmail: (value) => {
                that.props.componentMethods.setManagerState({logInUsersEmail: value, formInvalidLogInUsersEmail: false})
            },
            setLogInUsersPassword: (value) => {
                that.props.componentMethods.setManagerState({logInUsersPassword: value, formInvalidLogInPassword: false})
            },
            clearCreateAccountForm() {
                that.props.componentMethods.setManagerState({
                    createAccountUsersFirstName: "",
                    createAccountUsersLastName: "",
                    createAccountUsersEmail: "",
                    createAccountUsersDateOfBirthDay: "",
                    createAccountUsersDateOfBirthMonth: "",
                    createAccountUsersDateOfBirthYear: "",
                    createAccountUsersPassword: "",
                    createAccountUsersPasswordConfirm: "",
                    formInvalidCreateAccountPassword: false,
                    formInvalidCreateAccountPasswordConfirm: false,
                    createAccountUsersSexID: "",
                })
            },

            clearLogInForm:()  => {
                that.clearLogInForm()
            },
            showRecoverPasswordScreen: () => {
                that.props.componentMethods.setManagerState({recoverPasswordScreen: true})
            },
            hideRecoverPasswordScreen: () => {
                that.props.componentMethods.setManagerState({recoverPasswordScreen: false})
            },
            recoverUsersPasswordAction: () => {
                let requestObject = {
                    usersEmail: that.props.componentMethods.getManagerState().recoverUsersEmail
                }
                that.ajax(settings.API_URL + settings.API_ENDPOINT_USER_RECOVER_PASSWORD,requestObject,'POST',null, (data) => {
                   that.addError(that.props.componentConstants.language.LANG_SUCCESSFUL_RECOVER_EMAIL_SENT)
                })
              },
            setRecoverUsersEmail: (value) => {
                that.props.componentMethods.setManagerState({recoverUsersEmail:value})
            },
            isLoggedInUser: () => {
                return that.isLoggedInUser()
            },
            logoutUser: () => {
                that.saveLocalStorage(null, 'user')
                that.props.componentMethods.setManagerState({user: null, addresses: {
                    items: []
                }}, () => {
                    that.postShoppingCartAction()
                })
            },
            getShoppingCartItemsAction: () => {
                let url = settings.API_URL + settings.API_ENDPOINT_SHOPPINGCARTITEMS + that.props.componentMethods.getLocalStorage('shoppingCart').shoppingCartSha
                let accountToken = ''
                if(that.props.componentMethods.getLocalStorage('user') && that.props.componentMethods.getLocalStorage('user').tokensHash) {
                    accountToken = '?accountToken=' + that.props.componentMethods.getLocalStorage('user').tokensHash
                }
                that.props.componentMethods.setManagerState({loadingShoppingCartItems: true, shoppingCartItems: []}, () => {
                    that.ajax(url += accountToken, 
                        {}, 
                       "GET", 'shoppingCartItems', 
                        (data) => {
                           that.props.componentMethods.setManagerState({shoppingCartItems: data.items, loadingShoppingCartItems: false})
                       })
                })
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
                },
            getLocalStorage(storageName) {
                return that.getLocalStorage(storageName)
            },
            hasAccess: (accessLevel) => {
                return that.hasAccess(accessLevel)
            },
            hasExactAccess: (accessLevel) => {
                return that.hasExactAccess(accessLevel)
            },
            setCreateAccountErrors: (data) => {
                that.setCreateAccountErrors(data)
            },
            setSuccessfulUsersRegister : (data) => {
                that.setSuccessfulUsersRegister(data)
            },
            setSuccessfulUsersLogIn: (data) => {
                that.setSuccessfulUsersLogIn(data)
            },
            setSuccessfulUsersAccountUpdate: (data) => {
                that.setSuccessfulUsersAccountUpdate(data)
            },
            setUpdateAccountErrors: (data) => {
                that.setUpdateAccountErrors(data)
            }
        })
    }
    mergeShoppingCart() {
        let that = this
        let url = settings.API_URL + settings.API_ENDPOINT_SHOPPINGCART_MERGE + that.props.componentMethods.getLocalStorage('shoppingCart').shoppingCartSha
        let accountToken = '?accountToken=' + that.props.componentMethods.getLocalStorage('user').tokensHash
        that.ajax(url += accountToken, 
            {}, 
           "GET", 'shoppingCartItems', 
            (data) => {
               that.postShoppingCartAction()
           },{}, 
           false, 
           () => {},
           () => {}, true)
    }
    clearLogInForm() {
        this.props.componentMethods.setManagerState({
            logInUsersEmail: "",
            logInUsersPassword: "",
        })
    }
    getUserAddresses() {
        const that = this;
        that.props.componentMethods.setManagerState({addresses: {
            items: []
        }}, () => {
            if(that.getLocalStorage('user')) {
                that.ajax(settings.API_URL + settings.API_ENDPOINT_ADDRESS + '?accountToken=' + that.getLocalStorage('user').tokensHash,{},'GET','addresses', (data) => {
                    that.setUserAddresses(data)
                })
            }    
        })
    }
    getShoppingCartTotal() {
        const that = this
        let accountToken = ''
        if(this.getLocalStorage('user')) {
            accountToken = '?accountToken=' + this.getLocalStorage('user').tokensHash;
        }
        this.ajax(settings.API_URL + settings.API_ENDPOINT_SHOPPINGCART_TOTAL + this.getLocalStorage('shoppingCart').shoppingCartSha + accountToken, {},'GET','addresses', (data) => {
            that.props.componentMethods.setManagerState({shoppingCartTotal: data.items.total})
        })
    }
    setCreateAccountErrors(data) {
        let invalidFields = {}
        let errorMessage = [this.props.componentMethods.getManagerState().language["LANG_VALIDATION_INVALID_REQUEST"] ]
        if(data.fields.usersEmail) {
            invalidFields.formInvalidCreateAccountUsersEmail = true;
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersEmail"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersEmail.message])
        }
        if(data.fields.usersPassword) {
            invalidFields.formInvalidCreateAccountPassword = true;
            invalidFields.formInvalidCreateAccountPasswordConfirm = true;
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersPassword"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersPassword.message])
        }

        if(data.fields.usersFirstName) {
            invalidFields.formInvalidCreateAccountUsersFirstName = true;
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersFirstName"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersFirstName.message])
        }
        if(data.fields.usersLastName) {
            invalidFields.formInvalidCreateAccountUsersLastName = true;
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersLastName"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersLastName.message])
        }
        if(data.fields.usersDateOfBirth) {
            invalidFields.formInvalidCreateAccountUsersDateOfBirth = true;
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersDateOfBirth"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersDateOfBirth.message])
        }

        if(data.fields.usersUsersSexID) {
            invalidFields.formInvalidCreateAccountUsersUsersSexID = true;
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersUsersSexID"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersUsersSexID.message])
        }

        this.addError(errorMessage.join(', '), invalidFields)
        this.props.componentMethods.setManagerState({loadingCreateAccount: false})
    }

    setLogInErrors(data) {
        let invalidFields = {}
        let errorMessage = [this.props.componentMethods.getManagerState().language[data.message] ]
            invalidFields.formInvalidLogInUsersEmail = true;
            invalidFields.formInvalidLogInPassword= true;
        if(data.fields.usersEmail) {
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersEmail"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersEmail.message])
        }

        if(data.fields.usersPassword) {
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersPassword"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersPassword.message])
        }
        this.addError(errorMessage.join(', '), invalidFields)
        this.props.componentMethods.setManagerState({loadingLogin: false})
    }
    setUpdateAccountErrors(data) {
        this.ajax(settings.API_URL + settings.API_ENDPOINT_MY_ACCOUNT + '?accountToken=' + this.props.componentMethods.getManagerState().user.tokensHash,{},'GET',null, (data) => {this.setYourAccountData(data)})
        this.props.componentMethods.setManagerState({loadingYourAccount: false})
        let invalidFields = {}
        let errorMessage = [this.props.componentMethods.getManagerState().language[data.message] ]
       
        if(data.fields.usersEmail) {
            invalidFields.formInvalidYourAccountUsersEmail = true;
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersEmail"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersEmail.message])
        }

        if(data.fields.usersPassword) {
            invalidFields.formInvalidYourAccountNewPassword = true;
            invalidFields.formInvalidYourAccountNewPasswordConfirm = true;
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersPasswordCheck"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersPassword.message])
        }

        if(data.fields.usersPasswordCheck) {
            invalidFields.formInvalidYourAccountOldPassword = true;
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersPasswordCheck"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersPassword.message])
        }

        if(data.fields.usersFirstName) {
            invalidFields.formInvalidYourAccountUsersFirstName = true;
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersFirstName"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersFirstName.message])
        }
        if(data.fields.usersLastName) {
            invalidFields.formInvalidYourAccountUsersLastName = true;
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersLastName"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersLastName.message])
        }
        if(data.fields.usersDateOfBirth) {
            invalidFields.formInvalidYourAccountUsersDateOfBirth = true;
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersDateOfBirth"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersDateOfBirth.message])
        }

        if(data.fields.usersUsersSexID) {
            invalidFields.formInvalidYourAccountUsersUsersSexID = true;
            errorMessage.push(this.props.componentMethods.getManagerState().language["LANG_usersUsersSexID"] + ': ' + this.props.componentMethods.getManagerState().language[data.fields.usersUsersSexID.message])
        }

        this.addError(errorMessage.join(', '), invalidFields)
    }
    setYourAccountData(data) {
        this.props.componentMethods.setManagerState({
        yourAccountUsersLastName: data.items[0].usersLastName, 
        yourAccountUsersFirstName: data.items[0].usersFirstName, 
        yourAccountUsersEmail: data.items[0].usersEmail,
        yourAccountUsersDateOfBirthYear:data.items[0].usersBirthYear, 
        yourAccountUsersDateOfBirthMonth:data.items[0].usersBirthMonth, 
        yourAccountUsersDateOfBirthDay:data.items[0].usersBirthDay, 
        yourAccountUsersSexID: data.items[0].usersUsersSexID });
        let newUser = data.items[0]
        let oldUser = this.getLocalStorage('user')
        oldUser.usersEmail = newUser.usersEmail
        oldUser.usersFirstName = newUser.usersFirstName
        oldUser.usersLastName = newUser.usersLastName
        oldUser.usersUsersSexID = newUser.usersUsersSexID
        oldUser.usersDateOfBirth = newUser.usersBirthYear + '-' + newUser.usersBirthMonth + '-' + newUser.usersBirthDay
        this.saveLocalStorage(oldUser,'user')
    }
    setSuccessfulUsersRegister (data) {
        if (data.httpStatusCode === 200) {
            this.props.componentMethods.setManagerState({successfulUsersRegister: true, loadingCreateAccount: false})
        }
    }
    setSuccessfulUsersAccountUpdate (data) {
        this.setYourAccountData(data)
        if (data.httpStatusCode === 200) {
            this.addError(this.props.componentMethods.getManagerState().language["LANG_UPDATED_ACCOUNT"])
        }
        this.props.componentMethods.setManagerState({loadingYourAccount: false})
    }
    setSuccessfulUsersLogIn(data) {
        this.saveLocalStorage(data.items[0], 'user')
        this.isLoggedInUser(() => {this.setUserData(this.props)})
        this.props.componentMethods.setManagerState({loadingLogin: false})
    } 
    setCreateAccountYears (data) {
        this.props.componentMethods.setManagerState({createAccountYears: data})
    }

    setAddressCounties (data) {
        this.props.componentMethods.setManagerState({counties: data})
    }
    setUserAddresses (data) {
        this.props.componentMethods.setManagerState({addresses: data})
    }
    getRender() {
        this.loadComponentsProps()
        return <TitleAppBar {...this.buildComponentProps('TitleAppBar')} />
    }
}
