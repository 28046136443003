import React from "react";
import {
    Router
} from "react-router-dom";
import RouterSwitch from "./RouterSwitch";
import history from './History';
import Languages from "../../../Languages/Languages";
import Settings from '../Settings/Settings'
import FooterComponent from "../CommonComponents/FooterComponent/FooterComponent";
import Theme from '../../../Themes/ThemeWwwRadugalanCom'
import { MuiThemeProvider } from '@material-ui/core/styles';
class RouterComponent extends React.Component {
    constructor(props) {
        super(props);
        let lang = Languages[Settings.defaultLanguage]

        this.state = {
            language: lang,
            encryption: {},
            showSetEncryption: false,
            conversation: '',
            scrollPosition: {},
            unreadMessagesBadges: {},
            currentMessage: {},
            userList: {},
            pingWebsocket: true,
            messages: [],
            errors: [],
            createAccountUsersFirstName: "",
            createAccountUsersLastName: "",
            createAccountUsersEmail: "",
            createAccountUsersDateOfBirthDay: "",
            createAccountUsersDateOfBirthMonth: "",
            createAccountUsersDateOfBirthYear: "",
            createAccountUsersPassword: "",
            createAccountUsersPasswordConfirm: "",
            formInvalidCreateAccountPassword: false,
            formInvalidCreateAccountPasswordConfirm: false,
            createAccountUsersSexID: "",
            yourAccountUsersSexID: "",
            yourAccountUsersDateOfBirthDay: "",
            yourAccountUsersDateOfBirthMonth: "",
            yourAccountUsersDateOfBirthYear: "",
            logInUsersEmail: "",
            logInUsersPassword: "",
            createAccountYears: {
                items: []
            },
            counties: {
                items: []
            },
            addresses: {
                items: []
            },
            deliveryMethods:[],
            orders: [],
            userOrders: [],
            rewards: [],
            payments: [],
            userOrderItems: [],
            searchQuery: "",
            successfulUsersRegister: false,
            imagesResultset: [],
            videosResultset: [],
            loadingVideos: false,
            mediaResultset: [],
            loadingCreateAccount: false,
            loadingLogin: false,
            loadingYourAccount: false,
            loadingShoppingCartItems: false,
            shoppingCartItems: [],
            deliveryAddress: null,
            billingAddress: null,
            openOrderItemsDialog: false,
            showCurrentRewards: true,
            shoppingCartTotal: 0,
        }
    }

    componentDidMount() {
    }
    addError(error, invalidFields = {}) {
        let errors = this.state.errors
        errors.push(error)
        this.setState({errors: errors, ...invalidFields})
    }
    clearErrorMessage(index) {
        let errorsMessages = this.state.errors
        errorsMessages.splice(index, 1);
        this.setManagerState({errors: errorsMessages})
    }
    setManagerState(stateObject, callback = () => {}) {
        if(stateObject) {
            this.setState(stateObject,() => {
                this.refresh(callback)
            })
        }
    }

    getManagerState() {
        return this.state
    }
    setQueryParams (paramName, paramValue) {
        const url = new URL(window.location.href);

        url.searchParams.set(paramName, paramValue);

        return url
    }
    
    getQueryParams (param) {
        const urlParams = new URLSearchParams(window.location.search);
        const queryParam = urlParams.get(param);
        return queryParam;
    }

    render() {
        return (<MuiThemeProvider theme={Theme}>
            <Router history={history}>
                <RouterSwitch
                history={history} 
                getManagerState={() => { return this.getManagerState()}}
                websocketSend={(data, type)=>{this.websocketSend(data, type)}}
                setManagerState={(stateObject, callback) => {this.setManagerState(stateObject, callback)}}
                setScrollPosition={(scrollPos) => {this.setScrollPosition(scrollPos)}}
                clearErrorMessage={(index) => {this.clearErrorMessage(index)}}
                setQueryParams={(paramName, paramValue) => this.setQueryParams(paramName, paramValue)}
                getQueryParams={(param) => this.getQueryParams(param)}
                addError={(errorMessage, stateObject) => {
                    this.addError(errorMessage, stateObject)
                }}
                />
                <FooterComponent getManagerState={() => { return this.getManagerState()}} />
            </Router>
            </MuiThemeProvider>
        );
    }
    refresh(callback = () => {}) {
        this.forceUpdate()
        callback()
    }
}

export default RouterComponent