import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/UploadPaperStyle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Settings from '../../../Settings/Settings';
import $ from "jquery";
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function CreateAccountPaper(props) {
  const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const [state, setState] = React.useState({
        imagesPublished: true,
      });
      const [uploadImagesLocationName, setUploadImagesLocationName] = React.useState()
      const [uploadImagesDescription, setUploadImagesDescription] = React.useState()

      const handleChangeImagesPublished = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
      };
    const chooseImage = () => {
        document.getElementById('UPLOAD_IMAGE_FILE').click()
    }
    const onChangeImage = () => {
        const [file] = document.getElementById('UPLOAD_IMAGE_FILE').files
        if (file) {
            document.getElementById('UPLOAD_IMAGE_PREVIEW').src = URL.createObjectURL(file)
        }
    }
    const clearForm = () => {
        $('#UPLOAD_IMAGES').trigger("reset");
        setUploadImagesLocationName("")
        setUploadImagesDescription("")
    }
      
    const uploadImage = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        $.ajax({
            url: Settings.API_URL + Settings.API_ENDPOINT_UPLOAD_IMAGES_WITHOUT_VARIATIONS+ '?accountToken=' + componentMethods.getManagerState().user.tokensHash,
            type: 'POST',
            data: formData,
            success: function (data) {
                componentMethods.setManagerState({uploadingImage: false}, () => {
                clearForm()
                })
            },
            error: function (request, status, error) {
              componentMethods.addError(componentConstants.language["LANG_ERROR_COULD_NOT_LOAD_IMAGE"], {uploadingImage: false})
            },
            cache: false,
            contentType: false,
            processData: false
        });
        componentMethods.setManagerState({uploadingImage: true})
    }
    if(componentMethods.getManagerState().uploadingImage) {
        return <div className={classes.root}>
                    <CircularProgress />{componentConstants.language["LANG_LOADING"]}
                </div>
    }
  return (
    <form className={classes.root} id={'UPLOAD_IMAGES'} noValidate autoComplete="off" onSubmit={(e) => {uploadImage(e)}}>
        <Typography variant="h5" component="h5" className={classes.title}>
            {componentConstants.language.LANG_UPLOAD_IMAGE}
        </Typography>
        <input
        onChange={onChangeImage}
        accept="image/jpeg"
        className={classes.input}
        style={{ display: 'none' }}
        name={'imageFile'}
        id="UPLOAD_IMAGE_FILE"
        multiple
        type="file"
        />
        <img alt="" src="" id="UPLOAD_IMAGE_PREVIEW" className={classes.uploadImagePreview} />
        <Button onClick={chooseImage} variant="contained" disableElevation color="primary" fullWidth={true} className={classes.closeButton}>
            {componentConstants.language.LANG_CHOOSE_IMAGE}
        </Button>
        <TextField name={'imagesLocationName'} value={uploadImagesLocationName} error={componentMethods.getManagerState().formInvalidUploadImagesLocationName} className={classes.formFullWidth} onChange={(event) => {setUploadImagesLocationName(event.target.value)}} id="UPLOAD_IMAGES_LOCATION_NAME" label={componentConstants.language.LANG_UPLOAD_IMAGE_LOCATION_NAME} fullWidth={true} />
        <TextField name={'imagesDescription'} value={uploadImagesDescription} error={componentMethods.getManagerState().formInvalidUploadImagesDescription} className={classes.formFullWidth} onChange={(event) => {setUploadImagesDescription(event.target.value)}} id="UPLOAD_IMAGES_DESCRIPTION" label={componentConstants.language.LANG_UPLOAD_IMAGE_DESCRIPTION} fullWidth={true} />
        <FormControlLabel fullWidth={true} className={classes.formFullWidth}
        control={<Checkbox value={1} checked={state.imagesPublished} onChange={handleChangeImagesPublished} name="imagesPublished" />}
        label={componentConstants.language["LANG_IMAGE_PUBLISHED"]}
      />
        <Button type={"submit"} variant="contained" disableElevation color="secondary" fullWidth={true} className={classes.closeButton}>
            {componentConstants.language.LANG_UPLOAD}
        </Button>
        <Button onClick={componentMethods.handleClosePopover} variant="outlined" color="secondary" fullWidth={true} className={classes.closeButton}>
            {componentConstants.language.LANG_CLOSE}
        </Button>
    </form>
  );
}