import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import settings from '../../Settings/Settings';
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

export default class RecoverPasswordComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            history: props.history,
            user: props.getManagerState().user,
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.props.setManagerState({
            recoverUsersNewPassword: '',
            recoverUsersNewPasswordConfirm: ''

        })
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            ...this.basicComponentConstants
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            }
        })
    }
    recoverUsersPasswordAction() {
        const that = this

        if (this.props.getManagerState().recoverUsersNewPassword !== this.props.getManagerState().recoverUsersNewPasswordConfirm) {
            that.addErrorMain(that.props.getManagerState().language.LANG_PASSWORD_MISMATCH)
            return
        }
        if(!this.props.getManagerState().recoverUsersNewPassword) {
            that.addErrorMain(that.props.getManagerState().language.LANG_PASSWORD_EMPTY)
            return   
        }
        let requestObject = {
            usersNewPassword: this.props.getManagerState().recoverUsersNewPassword,
        }
        if(this.props.urlParams && this.props.urlParams.uET) {
            requestObject.usersEmailToken = this.props.urlParams.uET
        }
        if(this.props.urlParams && this.props.urlParams.uCF) {
            requestObject.usersConfirmationString = this.props.urlParams.uCF
        }
        this.ajax(settings.API_URL + settings.API_ENDPOINT_USER_RECOVER_PASSWORD_NEW,requestObject,'POST','changePassword', (data) => {
            that.addErrorMain(that.props.getManagerState().language.LANG_PASSWORD_CHANGED_SUCCESSFULLY)
            that.props.setManagerState({showSuccessPasswordChange: true})
        }, 
        {}, 
        false, 
        () => {
            that.addErrorMain(that.props.getManagerState().language.LANG_PASSWORD_NOT_CHANGED)
        })
        console.log(requestObject)
    }
    getRender() {

        let classes = {
            root: {
              display: 'block',
              maxWidth: '750px',
              margin: '70px auto',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            closeButton: {
                margin: "8px auto"
            }
          };

        this.loadComponentsProps()

        return <div style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    {
                        this.props.getManagerState().showSuccessPasswordChange ?
                            <Typography variant="h5" component="h5" className={classes.title}>
                                {this.props.getManagerState().language.LANG_PASSWORD_CHANGED_SUCCESSFULLY}
                            </Typography>
                        :
                        <React.Fragment>
                            <Typography variant="h5" component="h5" className={classes.title}>
                                {this.props.getManagerState().language.LANG_RECOVER_PASSWORD}
                            </Typography>
                            <TextField type="password" value={this.props.getManagerState().recoverUsersNewPassword} className={classes.formFullWidth} onChange={(event) => {this.props.setManagerState({recoverUsersNewPassword:event.target.value})}} id="RECOVER_PASSWORD_NEW_PASSWORD" label={this.props.getManagerState().language.LANG_NEW_PASSWORD} fullWidth={true} />
                            <TextField type="password" value={this.props.getManagerState().recoverUsersNewPasswordConfirm} className={classes.formFullWidth} onChange={(event) => {this.props.setManagerState({recoverUsersNewPasswordConfirm:event.target.value})}} id="RECOVER_PASSWORD_NEW_PASSWORD" label={this.props.getManagerState().language.LANG_CONFIRM_NEW_PASSWORD} fullWidth={true} />
                            <Button onClick={() => {this.recoverUsersPasswordAction()}} variant="contained" disableElevation color="primary" fullWidth={true} style={classes.closeButton}>
                                {this.props.getManagerState().language.LANG_RECOVER}
                            </Button> 
                        </React.Fragment>

                    }
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}
