import React from 'react';
import { useHistory } from "react-router-dom";
import {
    Route
} from "react-router-dom";
import HomeComponent from '../CommonComponents/HomeComponent/HomeComponent'
import MyPicturesComponent from '../CommonComponents/MyPicturesComponent/MyPicturesComponent';
import UserPicturesComponent from '../CommonComponents/UserPicturesComponent/UserPicturesComponent';
import {
    Switch
} from "react-router-dom";
import { useParams
} from "react-router-dom";
import ImagePopup from '../CommonComponents/ImageComponent/ImagePopup';
import RecoverPasswordComponent from '../CommonComponents/RecoverPasswordComponent/RecoverPasswordComponent';
import CookiesSnackbarComponent from '../CommonComponents/CookiesSnackbarComponent/CookiesSnackbarComponent'
import StaticPageComponent from '../CommonComponents/StaticPagesComponent/StaticPageComponent';
import MyVideosComponent from '../CommonComponents/MyVideosComponent/MyVideosComponent';
import VideoPopup from '../CommonComponents/ImageComponent/VideoPopup';
function MyVideosSearchComponent(props) {
    let urlParams = useParams();
    return <React.Fragment>                
                <MyVideosComponent {...props} search={true} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function HomeComponentSearch(props) {
    let urlParams = useParams();
    return <React.Fragment>
                {
                urlParams.image ?
                    <ImagePopup {...props}  urlParams={urlParams} />
                :
                null
                }  
                <HomeComponent {...props} search={true} urlParams={urlParams} />
            </React.Fragment>
}
function VideoPage(props) {
    let urlParams = useParams();
    return <React.Fragment>
                {
                urlParams.video ?
                    <VideoPopup {...props}  urlParams={urlParams} />
                :
                null
                }  
                <HomeComponent {...props} search={true} urlParams={urlParams} />
            </React.Fragment>
}
function HomeComponentWithoutSearch(props) {
    let urlParams = useParams();
    return <React.Fragment>
                <HomeComponent {...props} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function HomeComponentWithoutSearchAndWithImage(props) {
    let urlParams = useParams();
    return <React.Fragment>
                <ImagePopup {...props}  urlParams={urlParams} />
                <HomeComponent {...props} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function MyPicturesComponentWithoutSearch(props) {
    return <React.Fragment>
                <MyPicturesComponent {...props} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function MyPicturesComponentWithoutSearchAndImage(props) {
    let urlParams = useParams();
    return <React.Fragment>
                <ImagePopup {...props} urlParams={urlParams} />
                <MyPicturesComponent {...props}  urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function MyPicturesSearchComponent(props) {
    let urlParams = useParams();
    return <React.Fragment>
                {
                urlParams.image ?
                    <ImagePopup {...props}  urlParams={urlParams} />
                :
                null
                }       
                <MyPicturesComponent {...props} search={true} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function MyPicturesWithoutSearchAndWithPagination(props) {
    let urlParams = useParams();
    return <React.Fragment>
                {
                urlParams.image ?
                    <ImagePopup {...props}  urlParams={urlParams} />
                :
                null
                }       
                <MyPicturesComponent {...props} search={false} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}
function UserPicturesSearchComponent(props) {
    let urlParams = useParams();
    return <React.Fragment>
                 {
                urlParams.image ?
                    <ImagePopup {...props}  urlParams={urlParams} />
                :
                null
                }   
                <UserPicturesComponent {...props} search={true} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}

function UserPicturesWithoutSearchComponent(props) {
    let urlParams = useParams();
    console.log(urlParams)
    return <React.Fragment>
                {
                urlParams.image ?
                    <ImagePopup {...props}  urlParams={urlParams} />
                :
                null
                }   
                <UserPicturesComponent {...props} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
        </React.Fragment>
}
function RecoverPassword(props) {
      let urlParams = useParams();
    return <React.Fragment>
                <RecoverPasswordComponent {...props} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}
function StaticPage(props) {
    let urlParams = useParams();
    return <React.Fragment>
                <StaticPageComponent {...props} urlParams={urlParams} />
                <CookiesSnackbarComponent {...props} />
            </React.Fragment>
}
export default function RouterSwitch(props) {
    let history = useHistory();
    function changeRoute(route) {
        history.push(route);
    }

    return (<Switch history={history}>
                <Route path="/recover/password/:uET/:uCF">
                    <RecoverPassword {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/:user/image/:image">
                    <UserPicturesWithoutSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/page/:page/:user/:query/image/:image">
                    <UserPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/page/:page/:user/image/:image">
                    <UserPicturesWithoutSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/:user/:query/image/:image">
                    <UserPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/page/:page/:user/:query">
                    <UserPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/page/:page/:user">
                    <UserPicturesWithoutSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/:user/:query">
                    <UserPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/user/pictures/:user">
                    <UserPicturesWithoutSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/image/:image">
                    <MyPicturesComponentWithoutSearchAndImage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/page/:page/image/:image">
                    <MyPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/page/:page/:query/image/:image">
                    <MyPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/:query/image/:image">
                    <MyPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/page/:page/:query">
                    <MyPicturesSearchComponent {...props} search={true} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/page/:page">
                    <MyPicturesWithoutSearchAndWithPagination {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures/:query">
                    <MyPicturesSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/pictures">
                    <MyPicturesComponentWithoutSearch {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/videos/page/:page/:query">
                    <MyVideosSearchComponent {...props} search={true} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/videos/page/:page">
                    <MyVideosSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/videos/:query">
                    <MyVideosSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/my/videos">
                    <MyVideosSearchComponent {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/page/:page/search/:query/image/:image">
                    <HomeComponentSearch {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/page/:page/search/:query">
                    <HomeComponentSearch {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/search/:query/image/:image">
                    <HomeComponentSearch {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/search/:query">
                    <HomeComponentSearch {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/page/:page/image/:image">
                    <HomeComponentWithoutSearchAndWithImage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/image/:image">
                    <HomeComponentWithoutSearchAndWithImage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/video/:video">
                    <VideoPage {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/page/:page">
                    <HomeComponentWithoutSearch {...props} changeRoute={changeRoute} />
                </Route>
                <Route path="/gdpr">
                    <StaticPage page="gdpr"  {...props} changeRoute={changeRoute} />
                    <CookiesSnackbarComponent {...props} />
                </Route>   
                <Route path="/">
                    <HomeComponentWithoutSearch {...props} changeRoute={changeRoute} />
                </Route>    
            </Switch>)
}