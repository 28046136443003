export default function Style(theme) {
    const style = {
        root: {
            maxWidth: 350,
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        formFullWidth: {
            width: '100%',
            height: 'auto'
        },
        title: {
            width: "100%",
            height: "auto",
            textAlign: "center"
        },
        formControl: {
            width: '30%',
        },
        formControlRadioGroup: {
            width: 'auto',
            margin: 0,
            display:'inline-block'
        },
        formRadioGroup: {
            flexDirection: 'row'
        },
        formControlSelect: {
            width: '25%',
            margin: '0 8px',
            height: "auto"
        },
        radioGroupFormControlLabel: {
            width: '30%'
        },
        closeButton: {
            width: "100%",
            height:"auto"
        },
        proceedToCheckout: {
            textDecoration: 'none',
            display: "block",
            margin: "0 5px",
            width: "100%",
            color: 'inherit'
          }
      }
    return style
}