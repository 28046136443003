export default function Style(theme) {
    const style = {
      root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        width: "100%"
      },
      input: {
        marginLeft: theme.spacing(1),
        flex: 1,
      },
      iconButton: {
        padding: 10,
      },
      divider: {
        height: 28,
        margin: 4,
      },
    }
    return style
}