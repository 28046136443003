import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Theme from '../../../../Themes/ThemeArtLexie';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Settings from '../../Settings/Settings'
import ImageContainer from '../ImageComponent/ImageContainer'
import Grid from '@material-ui/core/Grid';
import SearchUserImages from './MaterialComponents/SearchUserImages'
import Pagination from '../Pagination/PaginationComponent'
import LoadingComponent from '../LoadingComponent/LoadingComponent';

export default class UserPicturesComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.refDiv = React.createRef()
    }

    componentDidMount() {
        super.componentDidMount()
        let endpoint = Settings.API_ENDPOINT_USER_IMAGES + this.props.urlParams.user
        
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_USER_IMAGES + this.props.urlParams.user +  '?q=' + this.props.urlParams.query

            if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
                endpoint += '&page=' + this.props.urlParams.page
            }
        } else {
            if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
                endpoint += '?page=' + this.props.urlParams.page
            }
        }
        this.getSearchYourImages(endpoint)
    }

    changePage(page) {
        let endpoint = Settings.API_ENDPOINT_USER_IMAGES + this.props.urlParams.user + '?page=' + page
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_USER_IMAGES + this.props.urlParams.user
            if(this.props.urlParams.query) {
                endpoint += '?q=' + this.props.urlParams.query + '&page=' + page
            } else {
                endpoint += '?page=' + page
            }
        }
        this.getSearchYourImages(endpoint)
    }

    getSearchYourImages(endpoint) {
        this.props.setManagerState({imagesResultset: [], currentPagination: null, loadingImages: true}, () => {
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({imagesResultset: data.items, currentPagination: data.pagination, loadingImages: false})
            })
        })
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            language: that.props.getManagerState().language,
            history: that.props.history
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            }
        }).setComponentsConstants('ImageContainer', {
            language: that.props.getManagerState().language,
        }).setComponentsMethods('ImageContainer', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           }
        }).setComponentsConstants('SearchUserImages', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchUserImages', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },

            getSearchYourImages: (endpoint) => {
                this.getSearchYourImages(endpoint)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'flex',
              flexWrap: 'wrap',
              margin: '80px auto',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            paper: {
              margin: '10px auto',
              padding: 10,
              width: '100%',
            },
            formContainer: {
              margin: '0 auto'
            }
          };
        this.loadComponentsProps()    

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('pictures')
        paginationPattern[1] = '/page/{{page}}'+ paginationPattern[1]
        paginationPattern = paginationPattern.join('pictures')

        return <div ref={this.refDiv} style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <Grid container>
                        <SearchUserImages {...this.buildComponentProps('SearchUserImages')} />
                        {
                            this.props.getManagerState().loadingImages ?
                                <Grid item xs={12} lg={12} md={12}>
                                    <LoadingComponent />
                                </Grid>
                            :
                            null
                        }
                        {
                            this.props.getManagerState().imagesResultset.map((el, idx) => {
                                return <ImageContainer {...this.buildComponentProps('ImageContainer')} element={el} key={idx} />
                            })
                        }
                    </Grid>
                    <Pagination  {...this.buildComponentProps('Pagination')} pattern={paginationPattern} pagination={this.props.getManagerState().currentPagination} />
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}