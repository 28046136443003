import color from '@material-ui/core/colors/blue';
export default function Style(theme) {
  const style = {
      root: {
        width: '100%',
        height: '100%',
        margin: '0 auto',
        display: "block",
        position: "fixed",
        top: 0,
        zIndex: 2500,
        backgroundColor: "white",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundColor: color[50],
        overflow: "scroll"
      },

      button: {
          // position: "absolute",
          // left: 10,
          // top: 10
          margin: '8px 0 0 8px'
      },
      buttonAddToCart: {
        position: "absolute",
        right: 10,
        top: 10
    },
      img: {
        margin: "0 auto",
        display: "block",
        width: "100%",
        height: "100%",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat:"no-repeat",
      },
    }
  return style
}