import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import MissionPageComponent from './MaterialComponents/MissionPageComponent';
import GDPRPageComponent from './MaterialComponents/GDPRPageComponent';
export default class StaticPageComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            history: props.history,
            user: props.getManagerState().user,
        }
    }

    componentDidMount() {
        let pageContent = null
        switch (this.props.page) {
            case 'gdpr':
                pageContent = <GDPRPageComponent />
                break;
            default:
                pageContent = <MissionPageComponent />
                break;
        }
        this.props.setManagerState({pageContent: pageContent})
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            ...this.basicComponentConstants
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            }
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'block',
              margin: '70px auto',
              maxWidth: '750px',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            img: {
                maxWidth: '150px',
                maxHeight: '150px'
            },
          };
        this.loadComponentsProps()
        
        return <div style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    {this.props.getManagerState().pageContent}
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}
