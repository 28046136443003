import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    margin: '0 auto',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  circularProgress: {
      margin: "0 auto",
      display: 'block'
  }
}));

export default function LoadingComponent() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" className={classes.circularProgress} />
    </div>
  );
}