import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from './Styles/ImagePopupStyle';
import Settings from '../../Settings/Settings'
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import $ from "jquery";
import { IconButton } from '@material-ui/core';
import { useRef } from 'react';
import ShareIcon from '@material-ui/icons/Share';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
const useStyles = makeStyles((theme) => (Style(theme)));

export default function VideoPopup(props) {
  const videoRef = useRef(null);
  const classes = useStyles();
  const [use360p, setUse360p] = React.useState(props.getQueryParams('resolution') === '360' || props.getQueryParams('resolution') === undefined)
  const [use720p, setUse720p] = React.useState(props.getQueryParams('resolution') === '720')
  const [use1080p, setUse1080p] = React.useState(props.getQueryParams('resolution') === '1080')
  const [maxWidth, setMaxWidth] = React.useState(320)
  const [image, setImage] = React.useState(null)

  useEffect(() => {
    getImageDetailsAction()
    if(use360p)
      set360()
    else if (use720p)
      set720()
    else if (use1080p)
      set1080()
  }, []);

  const set720 = () =>
  {
    setUse360p(false)
    setUse720p(true)
    setUse1080p(false)
    setMaxWidth(720)
    videoRef.current.src = Settings.API_URL + Settings.API_ENDPOINT_VIDEO_FILE + props.urlParams.video + '?thumbsWidth=720'
  }
  const set1080 = () =>
    {
      setUse360p(false)
      setUse720p(false)
      setUse1080p(true)
      setMaxWidth(1080)
      videoRef.current.src = Settings.API_URL + Settings.API_ENDPOINT_VIDEO_FILE + props.urlParams.video + '?thumbsWidth=1080'
    }

    const set360 = () =>
      {
        setUse360p(true)
        setUse720p(false)
        setUse1080p(false)
        setMaxWidth(360)
        videoRef.current.src = Settings.API_URL + Settings.API_ENDPOINT_VIDEO_FILE + props.urlParams.video + '?thumbsWidth=360'
      }
      const getImageDetailsAction = () => {
    $.ajax({
        url: Settings.API_URL + Settings.API_ENDPOINT_UPLOAD_VIDEOS + '/' + props.urlParams.video,
        type: 'GET',
        data: {},
        success: function (data) {
              if(data.items.length)
                setImage(data.items[0])
        },
        error: function (request, status, error) {
          let errors = componentMethods.getManagerState().errors
          errors.push(componentConstants.language["LANG_ERROR_COULD_NOT_LOAD_IMAGE"])
          componentMethods.setManagerState({errors:errors})
        },
        dataType: "json",
        cache: false,
        contentType: false,
        processData: false
    });
  }
  const closeImage = () => {
      const pathNameSplit = window.location.pathname.split("video")
      props.changeRoute(pathNameSplit[0])
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const componentConstants = {
      ...props.componentConstants,
      language: props.getManagerState().language
  }

  const componentMethods = {
      ...props.componentMethods,
      handleClosePopover: () => {
        handleClose()
      },
      setManagerState: (stateObject, callback) => {
        props.setManagerState(stateObject, callback)
      },
      getManagerState: () => {
        return props.getManagerState()
      },

    getLocalStorage: (storageName = 'state') => {
      // We need the try block because user may not permit our accessing localStorage.
      try {
          const serializedState = localStorage.getItem(storageName);
          if (serializedState === null) { // The key 'state' does not exist.
              return undefined;             // Let our reducer initialize the app.
          }

          return JSON.parse(serializedState)

      } catch (error) {
          return undefined // Let our reducer initialize the app.
      }
  },

  ajax: (url, 
    dataObject = {}, 
    method, stateValue = '', 
    callback = () => {}, 
    headers = {}, 
    setState = true, 
    catchCallback = () => {}) => {
    // adauga o fct de callback pe eroare (catchCallback) pe care o executi in catch
    let sendDataObj = {};
    let dataURLSearchParams = new URLSearchParams();
    for (let pDO in dataObject) {
        if (dataObject.hasOwnProperty(pDO)) {
            sendDataObj[pDO] = dataObject[pDO];
            dataURLSearchParams.append(pDO, dataObject[pDO]);
        }
    }

    let stateSet = {};
    let requestObject = {
        method: method,
        mode: "cors",
        cache: "no-cache",
        headers: headers,
    };

    switch(method) {
        case 'GET':
            break;
        default:
            requestObject.body = dataURLSearchParams
            break;
    }

    fetch(url, requestObject).then((response) => {
        const res = response.json();
        return res;
    }).then((data) => {
        if (data.success === true || data.httpStatusCode === 200) {
            stateSet[stateValue] = data;
            if (setState) {
                this.setState(stateSet, () => {
                    callback(data);
                })
            } else {
                callback(data)
            }
        } else {
            catchCallback(data);
        }
    }).catch((error) => {
    });
}

  }

  const componentProps = {
      ...props.componentProps
  }
  return (
    <div className={classes.root}>
            <div className={classes.img} style={{position:"absolute", left:0, top:0 }} >
              <video ref={videoRef}  poster={Settings.API_ENDPOINT_PATH_VIDEO_URL+ '?width=360&path=' + props.urlParams.video + '.png'} src={Settings.API_URL + Settings.API_ENDPOINT_VIDEO_FILE + props.urlParams.video + '?thumbsWidth=360'} style={{maxWidth: maxWidth, maxHeight: '100%', margin:'0 auto', display: "block", width: '100%'}} controls/>
              <a href={props.setQueryParams('resolution', 360)}><Button style={{margin: 8}} variant={use360p ? "contained": "outlined"} onClick={set360}>360</Button></a>
              <a href={props.setQueryParams('resolution', 720)}><Button style={{margin: 8}} variant={use720p ? "contained" : "outlined"} onClick={set720}>720</Button></a>
              <a href={props.setQueryParams('resolution', 1080)}><Button style={{margin: 8}} variant={use1080p ? "contained": "outlined"} onClick={set1080}>1080</Button></a>
              <Button startIcon={<ShareIcon />} onClick={() => {
                    navigator.clipboard.writeText(Settings.SOCIAL_MEDIA_SETTING_SITE_URL + 'video/' + props.urlParams.video)
                    props.addError(componentConstants.language.LANGUAGE_LINK_COPPIED_TO_CLIPBOARD)
                    }}>{componentConstants.language.LANG_SHARE}</Button>
            </div>
            <div className={classes.container}>
              <Button onClick={closeImage} variant="contained" color="secondary" className={classes.button}>
                <CancelIcon />
              </Button>          
       </div>
       {componentMethods.getManagerState().errors.map((element, index) => { 
          return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={() => {props.clearErrorMessage(index)}}/>
        })}
    </div>
  );
}