import React from 'react';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import IconButton from '@material-ui/core/IconButton';
import MediaQuery from '../../../../../CommonComponents/UseMediaQuery/MediaQueryComponent';
import { makeStyles } from '@material-ui/core/styles';
import CreateAccountPaper from './CreateAccountPaper';

const useStyles = makeStyles((theme) => ({
  button: {
      margin: '0 10px'
    },
}));
export default function CreateAccountPopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    props.componentMethods.clearCreateAccountForm()
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods,
      handleClosePopover: () => {
        handleClose()
      }
  }

  const componentProps = {
      ...props.componentProps
  }
  
  return (<div>
          <MediaQuery 
                    lg={<Button variant="outlined"  onClick={handleClick} className={classes.button} color="secondary" component="span" startIcon={<PersonAddIcon />}>
                    {componentConstants.language.LANG_CREATE_ACCOUNT}
                </Button>} 
                    sm={<IconButton aria-label="delete" className={classes.button}  onClick={handleClick}>
                    <PersonAddIcon color="secondary" />
                  </IconButton>} 
                    xs={<IconButton aria-label="delete" className={classes.button} onClick={handleClick}>
                    <PersonAddIcon color="secondary" />
                  </IconButton>} 
                    md={<IconButton aria-label="delete" className={classes.button}  onClick={handleClick}>
                    <PersonAddIcon color="secondary" />
                  </IconButton>} 
                    xl={<Button variant="outlined"   onClick={handleClick} className={classes.button} color="secondary" component="span" startIcon={<PersonAddIcon />}>
                    {componentConstants.language.LANG_CREATE_ACCOUNT}
                </Button>}  />
         <Popover
         id={id}
         open={open}
         anchorEl={anchorEl}
         onClose={handleClose}
         anchorOrigin={{
           vertical: 'bottom',
           horizontal: 'right',
         }}
         transformOrigin={{
           vertical: 'bottom',
           horizontal: 'right',
         }}
       >
         <CreateAccountPaper componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
       </Popover>
        </div>
  );
}