import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function MediaQueryMd(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.only('md'));
  if(matches) {
    return props.content
  } else {
    return null
  }
}

export default MediaQueryMd;