import primaryColor from '@material-ui/core/colors/blue';
import secondaryColor from '@material-ui/core/colors/blue';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
let themeSettings = {
    palette: {
        primary: {
            main:primaryColor[50],
            light:primaryColor[500],
            dark:primaryColor[200],
            contrastText: '#000',
        },
        secondary: {
            main:secondaryColor[500],
            light:secondaryColor[400],
            dark:secondaryColor[300],
            contrastText: '#FFF',
        },
        status: {
            danger: 'orange',
        }
    },
    themeName: 'Lexie Themes'
}
let theme = createTheme(themeSettings);
// export default theme;
export default responsiveFontSizes(theme);
export {primaryColor}
export {secondaryColor}