import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Style from './Styles/ImageContainerStyle';
import Settings from '../../Settings/Settings'
import CreateIcon from '@material-ui/icons/Create';
import Moment from 'react-moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'moment/dist/locale/ro';
import {
  Link
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import DeleteImageDialog from './DeleteImageDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import CardActionArea from '@material-ui/core/CardActionArea';
import LinkIcon from '@material-ui/icons/Link';


const useStyles = makeStyles((theme) => (Style(theme)));

export default function ImageContainer(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [myPictureLocation, setMyPictureLocation] = React.useState(props.element.imagesLocationName)
  const [myPictureDescription, setMyPictureDescription] = React.useState(props.element.imagesDescription)
  const [myPicturePublished, setMyPicturePublished] = React.useState(parseInt(props.element.imagesPublished))
  const [initialMyPictureLocation, setInitialMyPictureLocation] = React.useState(props.element.imagesLocationName)
  const [initialMyPictureDescription, setInitialMyPictureDescription] = React.useState(props.element.imagesDescription)
  const [initialMyPicturePublished, setInitialMyPicturePublished] = React.useState(parseInt(props.element.imagesPublished))
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const getPictureUrl = (imagePath) => {
    if(window.location.pathname === '/') {
      return '/image/' + imagePath
    } else {
      let concatString = '/'
      if (window.location.pathname.slice(-1) === '/') {
        concatString = ''
      }
      return window.location.pathname + concatString + 'image/' + imagePath
    }
  }
  const handleChangeImagesPublished = (event) => {
    setMyPicturePublished(event.target.checked);
  };
  const setInitialValues = () => {
    setMyPictureLocation(initialMyPictureLocation)
    setMyPictureDescription(initialMyPictureDescription)
    setMyPicturePublished(initialMyPicturePublished)
  }
  const updateInitialValuesWithMyPicture = () => {
    setInitialMyPictureDescription(myPictureDescription)
    setInitialMyPictureLocation(myPictureLocation)
    setInitialMyPicturePublished(myPicturePublished)
  }


  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  const saveImage = () => {
    const data = {
        imagesDescription: myPictureDescription,
        imagesLocationName: myPictureLocation,
        imagesPublished: myPicturePublished === false ? 0 : 1
    }
    updateInitialValuesWithMyPicture();
    componentMethods.updateImage(data, props.element.imagesID)
  }
  const dateToFormat = props.element.imagesUploadedDateTime;
  return (
    <Grid item xs={12} lg={3} md={4}>
      <DeleteImageDialog imageID={props.element.imagesID} componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} setOpenDeleteDialog={setOpenDeleteDialog} openDeleteDialog={openDeleteDialog} />
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Link className={classes.avatarLink } to={"/user/pictures/" + props.element.imagesUsersID}>
          <Avatar aria-label="recipe" className={classes.avatar}>
            {props.element.usersFirstName[0]}
          </Avatar>
          </Link>
        }
        action={
          <IconButton aria-label="share" onClick={() => {
            navigator.clipboard.writeText(Settings.SOCIAL_MEDIA_SETTING_SITE_URL + 'image/' + props.element.imagesPath)
            componentMethods.addError(componentConstants.language.LANGUAGE_LINK_COPPIED_TO_CLIPBOARD)
            }}>
            <LinkIcon />
          </IconButton>
        }
        title={<Link className={classes.userNameLink} to={"/user/pictures/" + props.element.imagesUsersID}>{props.element.usersFirstName + " " + props.element.usersLastName}</Link>}
        subheader={<Moment format="DD/MM/YYYY">{dateToFormat}</Moment>}
      />
      <CardActionArea>
      <Link to={getPictureUrl(props.element.imagesPath) }>
      <CardMedia
        className={classes.media}
        image={Settings.API_URL + Settings.API_ENDPOINT_FILE + props.element.imagesPath}
        title={props.componentConstants.language.LANG_IMAGE_PRE_TITLE + " " + props.element.usersFirstName + " " + props.element.usersLastName + " "+props.element.imagesLocationName + " " + props.element.imagesDescription}
      />
      </Link>
      </CardActionArea>
      <CardContent>
     
        <Typography variant="body2" color="textSecondary" component="p">
         {props.element.imagesLocationName + " " + props.element.imagesDescription}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        { props.canEdit ?
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <CreateIcon />
        </IconButton>
        :
        null
        }
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
           <TextField fullWidth={true} id="standard-basic" label={props.componentConstants.language["LANG_UPLOAD_IMAGE_LOCATION_NAME"]} value={myPictureLocation} onChange={(e) => {setMyPictureLocation(e.target.value)}} />
          </Typography>
          <Typography paragraph>
           <TextField fullWidth={true} id="standard-basic" label={props.componentConstants.language["LANG_UPLOAD_IMAGE_DESCRIPTION"]} value={myPictureDescription} onChange={(e) => {setMyPictureDescription(e.target.value)}} />
          </Typography>
          <FormControlLabel fullWidth={true} className={classes.formFullWidth}
        control={<Checkbox checked={myPicturePublished} onChange={handleChangeImagesPublished} />}
        label={props.componentConstants.language["LANG_IMAGE_PUBLISHED"]}
      />
          <Button fullWidth={true} variant="contained" color="secondary" className={classes.buttonSave} onClick={saveImage}>
              {props.componentConstants.language.LANG_SAVE}
          </Button>
          <Button fullWidth={true} variant="outlined" color="secondary" className={classes.buttonCancel} onClick={setInitialValues}>
              {props.componentConstants.language.LANG_CANCEL}
          </Button>
          <Button fullWidth={true} variant="contained" color="primary" className={classes.buttonDelete} onClick={(e)=>{setOpenDeleteDialog(true)}} startIcon={<DeleteIcon />}>
              {props.componentConstants.language.LANG_DELETE}
          </Button>
        </CardContent>
      </Collapse>
    </Card>
    </Grid>
  );
}