import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Theme from '../../../../Themes/ThemeArtLexie';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Settings from '../../Settings/Settings'
import ImageContainer from '../ImageComponent/ImageContainer'
import Grid from '@material-ui/core/Grid';
import SearchYourImages from './MaterialComponents/SearchYourImages'
import Button from '@material-ui/core/Button';
import Pagination from '../Pagination/PaginationComponent'
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import AddImage from './MaterialComponents/AddImage';
export default class MyPicturesComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.refDiv = React.createRef()
    }

    componentDidMount() {
        super.componentDidMount()
        if(this.getLocalStorage('user')) {
            let endpoint = Settings.API_ENDPOINT_MY_IMAGES + "?accountToken=" + this.getLocalStorage('user').tokensHash
            if(this.props.search === true) {
                endpoint = Settings.API_ENDPOINT_MY_IMAGES + '?q=' + this.props.urlParams.query  + "&accountToken=" + this.getLocalStorage('user').tokensHash
            }
    
            if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
                endpoint += '&page=' + this.props.urlParams.page
            }
            this.getSearchYourImages(endpoint)    
        } else {
            this.props.setManagerState({notLoadedMyPictures: true})
        }
    }
    loadImages() {
        let endpoint = Settings.API_ENDPOINT_MY_IMAGES + "?accountToken=" + this.props.getManagerState().user.tokensHash
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_MY_IMAGES + '?q=' + this.props.urlParams.query  + "&accountToken=" + this.props.getManagerState().user.tokensHash
        }
        if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
            endpoint += '&page=' + this.props.urlParams.page
        }
        this.getSearchYourImages(endpoint)
    }

    changePage(page) {
        let endpoint = Settings.API_ENDPOINT_MY_IMAGES + "?accountToken=" + this.props.getManagerState().user.tokensHash
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_MY_IMAGES + '?q=' + this.props.urlParams.query  + "&accountToken=" + this.props.getManagerState().user.tokensHash
        }
        endpoint += '&page=' + page
        this.getSearchYourImages(endpoint)
    }
    getSearchYourImages(endpoint) {
        this.props.setManagerState({imagesResultset: [], currentPagination: null, loadingImages: true}, () => {
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({imagesResultset: data.items,notLoadedMyPictures: false, currentPagination: data.pagination, loadingImages: false})
            })
        })
    }
    refreshSearchYourImages() {
        let endpoint = Settings.API_ENDPOINT_MY_IMAGES + "?accountToken=" + this.props.getManagerState().user.tokensHash
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_MY_IMAGES + '?q=' + this.props.urlParams.query  + "&accountToken=" + this.props.getManagerState().user.tokensHash
        }
        
        if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
            endpoint += '&page=' + this.props.urlParams.page
        }

        this.getSearchYourImages(endpoint)  
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            language: that.props.getManagerState().language,
            history: that.props.history
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           }
        }).setComponentsConstants('ImageContainer', {
            language: that.props.getManagerState().language,
        }).setComponentsMethods('ImageContainer', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            
            updateImage: (requestObject, imageID) => {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_PUT_IMAGES + imageID + '?accountToken=' + that.props.getManagerState().user.tokensHash,requestObject,'PUT',null, (data) => {that.addErrorMain(that.props.getManagerState().language.LANG_IMAGE_UPDATED_SUCCESSFULLY)}, {}, false, (data) => {that.addErrorMain(that.props.getManagerState().language.LANG_IMAGE_COULD_NOT_UPDATE)})
            },
            deleteImageAction: (imageID) => {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_PUT_IMAGES + imageID + '?accountToken=' + that.props.getManagerState().user.tokensHash,{},'DELETE',null, (data) => {that.addErrorMain(that.props.getManagerState().language.LANG_IMAGE_DELETED); this.refreshSearchYourImages()}, {}, false, (data) => {that.addErrorMain(that.props.getManagerState().language.LANG_IMAGE_NOT_DELETED)})
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           }
        }).setComponentsConstants('SearchYourImages', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchYourImages', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },

            getSearchYourImages: (endpoint) => {
                this.getSearchYourImages(endpoint)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'flex',
              flexWrap: 'wrap',
              margin: '80px auto',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            paper: {
              margin: '10px auto',
              padding: 10,
              width: '100%',
            },
            formContainer: {
              margin: '0 auto'
            },
            notLoadedMyPicturesButton: {
                margin: '0 auto'
            }
          };
        
        this.loadComponentsProps()    

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('pictures')
        paginationPattern[1] = '/page/{{page}}'+ paginationPattern[1]
        paginationPattern = paginationPattern.join('pictures')
        return <div ref={this.refDiv} style={classes.root} id="rootComponentMyPictures">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <Grid container id="mainContainer">
                        <SearchYourImages {...this.buildComponentProps('SearchYourImages')} />
                        <Grid item xs={12} lg={12} md={12}>
                            <AddImage {...this.buildComponentProps('AppbarComponent')} />
                        </Grid>
                        {
                            this.props.getManagerState().loadingImages ?
                                <Grid item xs={12} lg={12} md={12}>
                                    <LoadingComponent />
                                </Grid>
                            :
                            null
                        }
                        {this.props.getManagerState().notLoadedMyPictures ?
                        <Button onClick={() => {this.loadImages()}} variant="contained" color="primary" style={classes.notLoadedMyPicturesButton}>
                            {this.props.getManagerState().language.LOAD_IMAGES}
                        </Button>
                            :
                            this.props.getManagerState().imagesResultset.map((el, idx) => {
                                return <ImageContainer {...this.buildComponentProps('ImageContainer')} element={el} key={idx} canEdit={true} />
                            })
                        }
                        
                    </Grid>
                    <Pagination  {...this.buildComponentProps('Pagination')} pattern={paginationPattern} pagination={this.props.getManagerState().currentPagination} />
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}